import React, { useState, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty, useFirebase } from 'react-redux-firebase';
import { Menu, Sidebar, Icon } from 'semantic-ui-react';
import { createMedia } from '@artsy/fresnel';
import PropTypes from 'prop-types';

import logo from '../../images/logos/logo.png';
import SearchBox from '../../components/AutocompleteSearchBox';
import AccountMenu from './AccountMenu';
import ClientSelector from './ClientSelector';
import { DASHBOARD_PATH, PATHS_BY_ROLE } from '../../constants/paths';

const { Media, MediaContextProvider } = createMedia({
  breakpoints: { sm: 0, md: 768, lg: 1024, xl: 1192 },
});

const NavBar = ({ children }) => {
  const firebase = useFirebase();
  const auth = useSelector(state => state.firebase.auth);
  const authExists = isLoaded(auth) && !isEmpty(auth);
  const role = localStorage.getItem('role') || 'user';
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleLogout = async () => {
    localStorage.clear();
    await firebase.logout();
  };

  const switchRole = (newRole) => {
    localStorage.setItem('role', newRole);
    window.location.reload();
  };

  const leftItems = useMemo(() => PATHS_BY_ROLE[role] || [], [role]);

  const renderSidebar = (items, visible, toggleVisibility) => (
    <Sidebar
      as={Menu}
      animation="overlay"
      inverted
      onHide={toggleVisibility}
      vertical
      visible={visible}
    >
      {items.map(item => <Menu.Item as={NavLink} {...item} key={item.key} />)}
    </Sidebar>
  );

  const renderMenu = (items, searchBox) => (
    <Menu fixed="top" inverted>
      <Menu.Item as={NavLink} to={DASHBOARD_PATH} style={{ background: '#1B1C1D', height: '105%' }}>
        <img alt="logo" src={logo} style={{ marginTop: '4px' }} />
      </Menu.Item>
      {authExists && (
        <>
          {role === 'business' && <ClientSelector />}
          <Menu.Item style={{ flexGrow: 2 }}>{searchBox}</Menu.Item>
          {items.map(item => <Menu.Item as={NavLink} {...item} key={item.key} />)}
          <Menu.Menu position="right">
            <AccountMenu role={role} switchRole={switchRole} handleLogout={handleLogout} />
          </Menu.Menu>
        </>
      )}
    </Menu>
  );

  return (
    <>
      <MediaContextProvider>
        <Media lessThan="md">
          {renderSidebar(leftItems, sidebarVisible, () => setSidebarVisible(!sidebarVisible))}
          <Sidebar.Pusher dimmed={sidebarVisible}>
            {renderMenu(leftItems, <SearchBox />)}
            {children}
          </Sidebar.Pusher>
        </Media>
        <Media greaterThanOrEqual="md">
          {renderMenu(leftItems, <SearchBox />)}
          {children}
        </Media>
      </MediaContextProvider>
    </>
  );
};

NavBar.propTypes = {
  children: PropTypes.node,
};

export default NavBar;
