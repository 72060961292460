import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Form, Grid, Header, Modal } from "semantic-ui-react";
import ErrorMessage from "../../ErrorMessage";
import { getArtistNotes } from "../../../redux/actions/artist";
import { getGalleryNotes } from "../../../redux/actions/gallery";
import { createNote, updateNote } from "../../../redux/actions/notes";

const NotesModal = ({
  artistID,
  galleryID,
  create,
  update,
  fetchArtistNotes,
  fetchGalleryNotes,
  style,
}) => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [note, setNote] = useState(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    if (modalOpen) {
      setLoading(true);
      if (artistID) {
        fetchArtistNotes(artistID).then(({ payload }) => {
          setLoading(false);
          if (payload.data.length === 0) {
            setContent("");
          } else {
            setNote(payload.data[0]);
            setContent(payload.data[0].content);
          }
        });
      } else if (galleryID) {
        fetchGalleryNotes(galleryID).then(({ payload }) => {
          setLoading(false);
          if (payload.data.length === 0) {
            setContent("");
          } else {
            setNote(payload.data[0]);
            setContent(payload.data[0].content);
          }
        });
      }
    }
  }, [modalOpen]);

  const handleSubmit = async () => {
    setSaving(true);
    setError(null);

    try {
      if (note && note._id) {
        await update(note._id, { content });
      } else {
        await create({ content, artist_id: artistID, gallery_id: galleryID });
      }
      setModalOpen(false);
    } catch (e) {
      setError(e);
    }

    setSaving(false);
  };

  const handleClose = () => {
    setLoading(false);
    setModalOpen(false);
  };

  return (
    <Modal
      trigger={
        <Button
          style={style}
          basic
          icon={"sticky note outline"}
          content={"Personal Notes"}
          labelPosition={"left"}
          onClick={() => setModalOpen(true)}
        />
      }
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={handleClose}
      closeIcon
    >
      <Modal.Header>
        <Header
          content={"Personal Notes"}
          subheader={"*These notes are private and only visible to you"}
        />
      </Modal.Header>
      <Modal.Content scrolling loading>
        <Grid.Row>
          <Grid.Column width={16}>
            {error && <ErrorMessage error={error} />}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form loading={loading}>
              <Form.TextArea
                rows={4}
                name="notes"
                placeholder="Notes"
                defaultValue={content}
                onChange={(e, { value }) => setContent(value)}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Modal.Content>
      <Modal.Actions>
        <Button basic content={"Cancel"} onClick={() => handleClose()} />
        <Button
          primary
          loading={saving}
          content={"Save"}
          onClick={() => handleSubmit()}
        />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = ({ firebase }) => ({
  auth: firebase.auth || null,
});

const mapDispatchToProps = (dispatch) => ({
  fetchArtistNotes: (data) => dispatch(getArtistNotes(data)),
  fetchGalleryNotes: (data) => dispatch(getGalleryNotes(data)),
  create: (data) => dispatch(createNote(data)),
  update: (id, data) => dispatch(updateNote(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesModal);
