import React, { useState } from 'react';
import { Image, Modal, Button } from 'semantic-ui-react';
import placeholder from '../../../images/profile_placeholder.png';
import SearchArtist from '../../Search/SearchArtist';

const SelectArtist = ({ artist, onSelectArtist }) => {
  const [currentArtist, setCurrentArtist] = useState(artist);
  const [modalOpen, setModalOpen] = useState(false);

  const handleSelectArtist = (artist) => {
    setCurrentArtist(artist);
    onSelectArtist(artist);
    setModalOpen(false);
  };

  const handleRemoveArtist = () => {
    setCurrentArtist(undefined);
    onSelectArtist(undefined);
  };

  return (
    <Modal
      trigger={
        <div role="button" onClick={() => setModalOpen(true)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          <Image
            src={currentArtist?.small_image_url || placeholder}
            avatar
          />
          <span style={{ marginRight: '10px' }}>{currentArtist?.name || 'Select Artist'}</span>
          {currentArtist && (
            <Button icon="remove" onClick={(e) => {
              e.stopPropagation(); // Prevents opening the modal
              handleRemoveArtist();
            }} size="mini" />
          )}
        </div>
      }
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header>Select Artist</Modal.Header>
      <Modal.Content style={{ minHeight: '425px' }}>
        <SearchArtist onSelectArtist={handleSelectArtist} />
      </Modal.Content>
    </Modal>
  );
};

export default SelectArtist;