import { combineReducers } from "redux";

import auth from "./modules/auth";
import user from "./modules/user";

import piece from "./modules/piece";
import pieces from "./modules/pieces";

import artist from "./modules/artist";
import artists from "./modules/artists";

import gallery from "./modules/gallery";
import galleries from "./modules/galleries";

import location from "./modules/location";
import locations from "./modules/locations";

import gallery_proposal from "./modules/gallery_proposal";
import gallery_proposals from "./modules/gallery_proposals";

import artist_proposal from "./modules/artist_proposal";
import artist_proposals from "./modules/artist_proposals";

import complaints from "./modules/complaints";

import analytics from "./modules/analytics";

import appraisals from "./modules/appraisals";

import auction from "./modules/auction";
import auctions from "./modules/auctions";

import auction_house from "./modules/auction_house";
import auction_houses from "./modules/auction_houses";

import auction_records from "./modules/auction_records";
import auction_record from "./modules/auction_record";

import import_pieces from "./modules/import_pieces";
import import_piece from "./modules/import_piece";

import tags from "./modules/tags";
import tag from "./modules/tag";

import consignment from "./modules/consignments";
import notifications from "./modules/notifications";

import payment from "./modules/payment";

import subscription from "./modules/subscription";
import subscriptions from "./modules/subscriptions";

import private_room from "./modules/private_room";
import private_rooms from "./modules/private_rooms";

import me from "./modules/me";
import plans from "./modules/plans";
import payment_sources from "./modules/payment_sources";
import products from "./modules/products";
import referrals from "./modules/referrals";
import currencies from "./modules/currencies";

import artwork from "./modules/artwork";
import artworks from "./modules/artworks";

import museum from "./modules/museum";
import museums from "./modules/museums";

import daily_events from "./modules/daily_events";

import firebase from "react-redux-firebase/lib/reducer";
import firestore from "redux-firestore/lib/reducer";

export default combineReducers({
  firebase,
  firestore,

  user,

  piece,
  pieces,

  artist,
  artists,

  gallery,
  galleries,

  location,
  locations,

  complaints,
  appraisals,

  artistProposal: artist_proposal,
  artistProposals: artist_proposals,

  galleryProposal: gallery_proposal,
  galleryProposals: gallery_proposals,
  analytics,

  auction,
  auctions,

  auction_house,
  auction_houses,

  auctionRecord: auction_record,
  auctionRecords: auction_records,

  import_piece,
  import_pieces,

  tag,
  tags,

  consignment,
  notifications,
  me,
  payment,

  subscription,
  subscriptions,

  plans,
  payment_sources,

  products,
  referrals,

  privateRoom: private_room,
  privateRooms: private_rooms,

  currencies,

  artwork,
  artworks,

  museum,
  museums,

  daily_events,
});
