import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Form, Grid, Header, Modal } from "semantic-ui-react";
import download from "downloadjs";

import { createPiecePDF } from "../../../redux/actions/piece";
import ErrorMessage from "../../ErrorMessage";

const ExportModal = ({ piece, createPiecePDF }) => {
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [section, setSection] = useState("pieces");

  const [title, setTitle] = useState(true);
  const [date, setDate] = useState(true);

  const [medium, setMedium] = useState(true);
  const [description, setDescription] = useState(true);

  const [dimensions, setDimensions] = useState(true);

  const [artist, setArtist] = useState(true);
  const [gallery, setGallery] = useState(true);
  const [purchasePrice, setPurchasePrice] = useState(true);
  const [purchaseDate, setPurchaseDate] = useState(true);

  const [provenance, setProvenance] = useState(false);
  const [notes, setNotes] = useState(false);
  const [literature, setLiterature] = useState(false);
  const [exhibited, setExhibited] = useState(false);

  const [valuationPrice, setValuationPrice] = useState(true);

  const [edition, setEdition] = useState(true);
  const [inventoryIdentifier, setInventoryIdentifier] = useState(false);

  const handleSubmitPDF = async () => {
    const data = {
      title,
      date,
      artist,
      description,
      medium,
      dimensions,
      edition,

      provenance,
      literature,
      exhibited,
      valuation_price: valuationPrice,
      notes,

      gallery,
      purchase_price: purchasePrice,
      purchase_date: purchaseDate,
      inventory_identifier: inventoryIdentifier,
    };

    setLoadingPDF(true);

    try {
      const { payload } = await createPiecePDF(piece._id, data);
      download(payload.data.url);
      handleClose();
    } catch (e) {
      setError(e);
    }

    setLoadingPDF(false);
  };

  function handleClose() {
    setLoadingPDF(false);
    setModalOpen(false);
    setSection("pieces");
  }

  return (
    <Modal
      trigger={
        <Button
          basic
          icon="download"
          content="Export Artwork"
          labelPosition="left"
          floated={"right"}
          onClick={() => setModalOpen(true)}
        />
      }
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={handleClose}
      closeIcon
    >
      <Modal.Header
        content={
          section === "pieces"
            ? "What pieces would you like to download?"
            : "What fields would you like to include?"
        }
      />
      <Modal.Content scrolling>
        {error && <ErrorMessage error={error} />}
        <Form>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header content="Overview" />
                <Form.Checkbox
                  defaultChecked={title}
                  label="Title"
                  onChange={(e, { checked }) => setTitle(checked)}
                />
                <Form.Checkbox
                  defaultChecked={date}
                  label={"Date"}
                  onChange={(e, { checked }) => setDate(checked)}
                />
                <Form.Checkbox
                  defaultChecked={artist}
                  label="Artist"
                  onChange={(e, { checked }) => setArtist(checked)}
                />
                <Form.Checkbox
                  defaultChecked={medium}
                  label="Medium"
                  onChange={(e, { checked }) => setMedium(checked)}
                />
                <Form.Checkbox
                  defaultChecked={description}
                  label="Description"
                  onChange={(e, { checked }) => setDescription(checked)}
                />
                <Form.Checkbox
                  defaultChecked={dimensions}
                  label="Dimensions"
                  onChange={(e, { checked }) => setDimensions(checked)}
                />
                <Form.Checkbox
                  defaultChecked={edition}
                  label="Edition Information"
                  onChange={(e, { checked }) => setEdition(checked)}
                />
              </Grid.Column>
              <Grid.Column>
                <Header content="Additional Information" />
                <Form.Checkbox
                  defaultChecked={provenance}
                  label="Provenance"
                  onChange={(e, { checked }) => setProvenance(checked)}
                />
                <Form.Checkbox
                  defaultChecked={literature}
                  label="Literature"
                  onChange={(e, { checked }) => setLiterature(checked)}
                />
                <Form.Checkbox
                  defaultChecked={exhibited}
                  label="Exhibited"
                  onChange={(e, { checked }) => setExhibited(checked)}
                />
                <Form.Checkbox
                  defaultChecked={notes}
                  label="Notes"
                  onChange={(e, { checked }) => setNotes(checked)}
                />
                <Form.Checkbox
                  defaultChecked={inventoryIdentifier}
                  label={"Inventory Identifier"}
                  onChange={(e, { checked }) => setInventoryIdentifier(checked)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Header content="Financial Information" />
                <Form.Checkbox
                  defaultChecked={purchasePrice}
                  label="Purchase Price"
                  onChange={(e, { checked }) => setPurchasePrice(checked)}
                />
                <Form.Checkbox
                  defaultChecked={purchaseDate}
                  label="Purchase Date"
                  onChange={(e, { checked }) => setPurchaseDate(checked)}
                />
                <Form.Checkbox
                  defaultChecked={gallery}
                  label="Purchase Location"
                  onChange={(e, { checked }) => setGallery(checked)}
                />
                <Form.Checkbox
                  defaultChecked={valuationPrice}
                  label="Valuation Price"
                  onChange={(e, { checked }) => setValuationPrice(checked)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic content="Cancel" onClick={handleClose} />
        <Button
          primary
          loading={loadingPDF}
          content={"Export to PDF"}
          onClick={handleSubmitPDF}
        />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  createPiecePDF: (id, data) => dispatch(createPiecePDF(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportModal);
