import React, { useState } from "react";
import {
  addArtistToCurrentUserArtistList,
  deleteArtistFromCurrentUserArtistList,
} from "../../../../../../redux/actions/me";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import UpgradeModal from "../../../../../../components/UpgradeModal";

const ArtistFollowButton = ({
  artistId,
  collected_artists,
  user,
  unfollow,
  follow,
}) => {
  const [isFollowing, setIsFollowing] = useState(
    (user.artist_ids || []).includes(artistId),
  );

  if (collected_artists.includes(artistId)) return null;

  return (
    <UpgradeModal>
      <Button
        basic
        floated="right"
        icon={isFollowing ? "minus" : "plus"}
        content={isFollowing ? "Unfollow" : "Follow"}
        labelPosition={"left"}
        onClick={() => {
          if (isFollowing) {
            unfollow(artistId);
            setIsFollowing(false);
          } else {
            follow(artistId);
            setIsFollowing(true);
          }
        }}
      />
    </UpgradeModal>
  );
};

const mapStateToProps = ({ pieces, firebase }) => ({
  collected_artists: (pieces.data || [])
    .map((p) => p.artist)
    .filter((n) => n)
    .map((a) => a._id),
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({
  follow: (id) => dispatch(addArtistToCurrentUserArtistList({ artist: id })),
  unfollow: (id) => dispatch(deleteArtistFromCurrentUserArtistList(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistFollowButton);
