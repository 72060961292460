import React, { useState } from "react";
import { Button, Grid, Header, Item, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PRODUCT_PATH } from "../../../../../../../constants/paths";

const Billing = ({ user, createBilling }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Segment attached>
          <Item.Group divided>
            <Item>
              <Item.Content>
                <Item.Header content={"You are on a Free membership"} />
                <Item.Description>
                  <p>Upgrade to a Premium membership to unlock all features.</p>
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
        <Segment attached={"bottom"}>
          <Button
            primary
            as={Link}
            to={PRODUCT_PATH}
            content={"Upgrade"}
            labelPosition={"right"}
            icon={"angle right"}
          />
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

const mapStateToProps = ({ firebase }) => ({
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
