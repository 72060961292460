import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Header, Segment, Table } from "semantic-ui-react";
import { auth } from "../../../../../../utils/firebase";
import { fetchTwoFactorSettingList } from "../../../../../../redux/actions/two_factor_setting";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import CreateTwoFactorSetting from "./components/CreateTwoFactorSetting";
import DeleteTwoFactorSetting from "./components/DeleteTwoFactorSetting";

const TwoFactorSettings = ({ user, fetchAll }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [enrolledFactors, setEnrolledFactors] = useState(null);

  const fetchData = async () => {
    if (loading || error) return;
    setLoading(true);
    try {
      const factors = auth.currentUser.multiFactor.enrolledFactors;
      setEnrolledFactors(factors);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  return (
    <Segment basic loading={loading}>
      {error && <ErrorMessage error={error} />}
      <Table>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell content={"Phone Number"} />
            <Table.HeaderCell content={"Enrolled Since"} />
            <Table.HeaderCell width={5}>
              <CreateTwoFactorSetting
                userHasUpdatedEnrollment={() => window.location.reload()}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {(enrolledFactors || []).map((twoFactorSetting) => (
            <Table.Row key={twoFactorSetting.uid}>
              <Table.Cell content={twoFactorSetting.phoneNumber} />
              <Table.Cell content={twoFactorSetting.enrollmentTime} />
              <Table.Cell>
                <DeleteTwoFactorSetting
                  twoFactorSetting={twoFactorSetting}
                  userHasUpdatedEnrollment={() => window.location.reload()}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
};

const mapStateToProps = ({ firebase }) => ({
  user: firebase.profile,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAll: () => dispatch(fetchTwoFactorSettingList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorSettings);
