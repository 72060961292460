import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Menu, Modal, Segment, Table } from "semantic-ui-react";
import Tag from "../../../../models/tag";
import { useFirestoreConnect } from "react-redux-firebase";
import { deleteTag } from "../../../../redux/actions/tag";
import TagCell from "./components/TagCell";
import AddTagModal from "./components/AddTagModal";

const EditTagsModal = ({ auth, user }) => {
  const [modalOpen, setModalOpen] = useState(false);

  useFirestoreConnect([
    {
      orderBy: ["name", "asc"],
      collection: "tags",
      doc: (user && user.current_user_id) || auth.uid,
      subcollections: [{ collection: "tags" }],
      storeAs: "tags",
    },
  ]);

  const tags = useSelector((state) => state.firestore.ordered.tags);

  return (
    <Modal
      trigger={
        <Menu.Item
          icon={"ellipsis horizontal"}
          onClick={() => setModalOpen(true)}
        />
      }
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={() => setModalOpen(false)}
      closeIcon
    >
      <Modal.Header content={"Tags"} />
      <Modal.Content>
        <Table selectable>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell content={"Name"} />
              <Table.HeaderCell content={"Number of Pieces"} />
              <Table.HeaderCell textAlign={"center"}>
                <AddTagModal />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(tags || [])
              .map((tag) => new Tag(tag))
              .map((tag) => (
                <TagCell key={tag._id} tag={tag} />
              ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button basic content={"Cancel"} onClick={() => setModalOpen(false)} />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = ({ firebase }) => ({
  auth: firebase.auth || null,
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(deleteTag(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTagsModal);
