import React, { Children, cloneElement, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import SlidingPane from "react-sliding-pane";
import { connect } from "react-redux";
import {
  createPrivateRoom,
  updatePrivateRoom,
} from "../../../../../../redux/actions/private_room";
import { getPieceList } from "../../../../../../redux/actions/piece";
import UpgradeModal from "../../../../../../components/UpgradeModal";
import "react-sliding-pane/dist/react-sliding-pane.css";
import PiecesTable from "./PiecesTable";

const EditPrivateRoomSidePain = ({
  privateRoom,
  children,
  update,
  create,
  fetchAll,
}) => {
  const [sidePainOpen, setSidePainOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState(privateRoom.name);
  const [about, setAbout] = useState(privateRoom.about);
  const [pieces, setPieces] = useState(
    new Set((privateRoom.pieces || []).map((a) => a._id)),
  );

  const [description, setDescription] = useState(
    (privateRoom.fields && privateRoom.fields.description) || false,
  );
  const [artist, setArtist] = useState(
    (privateRoom.fields && privateRoom.fields.artist) || true,
  );
  const [gallery, setGallery] = useState(
    (privateRoom.fields && privateRoom.fields.gallery) || true,
  );
  const [purchasePrice, setPurchasePrice] = useState(
    (privateRoom.fields && privateRoom.fields.purchase_price) || false,
  );
  const [valuationPrice, setValuationPrice] = useState(
    (privateRoom.fields && privateRoom.fields.valuation_price) || false,
  );

  const loadData = async () => {
    await fetchAll();
  };

  useEffect(() => {
    loadData();
  }, []);

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    try {
      const data = {
        name,
        about,
        pieces: Array.from(pieces),
        fields: {
          description,
          artist,
          gallery,
          purchase_price: purchasePrice,
          valuation_price: valuationPrice,
        },
      };

      if (privateRoom._id) {
        await update(privateRoom._id, data);
      } else {
        await create(data);
      }

      setSidePainOpen(false);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  const handleSelectAll = (data) => {
    if (pieces.size !== data.length) {
      setPieces(new Set(data.map((piece) => piece._id)));
    } else {
      setPieces(new Set());
    }
  };

  const handleCheck = (id) => {
    if (pieces.has(id)) {
      pieces.delete(id);
    } else {
      pieces.add(id);
    }
    setPieces(new Set(pieces));
  };

  return (
    <>
      <SlidingPane
        className="some-custom-class"
        overlayClassName="overlay"
        isOpen={sidePainOpen}
        title={
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header
                  floated={"left"}
                  content={
                    privateRoom._id
                      ? "Edit Private Room"
                      : "Create Private Room"
                  }
                />

                <Button
                  primary
                  content={"Save"}
                  floated={"right"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
        onRequestClose={() => setSidePainOpen(false)}
      >
        <Grid stackable>
          <Grid.Row columns={1}>
            <Grid.Column>
              {error && (
                <Message
                  negative
                  header={error.error}
                  content={error.message}
                />
              )}
              <Form>
                <Form.Input
                  defaultValue={name}
                  label={"Name"}
                  placeholder="Name"
                  autoComplete={"off"}
                  onChange={(e, { value }) => setName(value)}
                />

                <Form.TextArea
                  rows={5}
                  placeholder="About"
                  defaultValue={about}
                  label="About"
                  autoComplete={"off"}
                  onChange={(e, { value }) => setAbout(value)}
                />

                <Form.Field>
                  <label>Items to show</label>
                  <Form.Group>
                    <Form.Checkbox
                      defaultChecked={description}
                      label={"Description"}
                      onChange={(e, { checked }) => setDescription(checked)}
                    />
                    <Form.Checkbox
                      defaultChecked={artist}
                      label="Artist"
                      onChange={(e, { checked }) => setArtist(checked)}
                    />
                    <Form.Checkbox
                      defaultChecked={gallery}
                      label="Gallery"
                      onChange={(e, { checked }) => setGallery(checked)}
                    />
                    <Form.Checkbox
                      defaultChecked={purchasePrice}
                      label="Purchase Price"
                      onChange={(e, { checked }) => setPurchasePrice(checked)}
                    />
                    <Form.Checkbox
                      defaultChecked={purchasePrice}
                      label={"Valuation Price"}
                      onChange={(e, { checked }) => setValuationPrice(checked)}
                    />
                  </Form.Group>
                </Form.Field>
                <Form.Field>
                  <label>Pieces</label>
                  <PiecesTable
                    selectedPieces={pieces}
                    handleCheck={handleCheck}
                    handleSelectAll={handleSelectAll}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SlidingPane>

      <UpgradeModal>
        {Children.map(children, (child) => {
          return cloneElement(
            child,
            {
              onClick: () => setSidePainOpen(true),
            },
            null,
          );
        })}
      </UpgradeModal>
    </>
  );
};

EditPrivateRoomSidePain.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
};

const mapStateToProps = ({ firebase, privateRoom }) => ({
  user: firebase.profile || null,
  error: privateRoom.error,
});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createPrivateRoom(data)),
  update: (id, data) => dispatch(updatePrivateRoom(id, data)),
  fetchAll: (page, limit, sort, direction) =>
    dispatch(getPieceList(page, limit, sort, direction)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditPrivateRoomSidePain);
