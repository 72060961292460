import AuctionPerformance from "./auction_performance";

let formatter = Intl.NumberFormat("en", {
  notation: "compact",
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

class MarketSignal {
  constructor(data) {
    this.auction_performance = data.auction_performance.map(
      (d) => new AuctionPerformance(d),
    );
  }

  totalAmountSold() {
    let performances = (this.auction_performance || []).filter(
      (item) => item.id !== "notSold",
    );
    let total = performances.reduce((previousValue, currentValue) => {
      return previousValue + (currentValue.total_amount || 0);
    }, 0);
    return formatter.format(total / 100);
  }

  numberOfLotsSold() {
    let performances = (this.auction_performance || []).filter(
      (item) => item.id !== "notSold",
    );
    return performances.reduce((previousValue, currentValue) => {
      return previousValue + (currentValue.number_of_lots || 0);
    }, 0);
  }

  lotsOffered() {
    return (this.auction_performance || []).reduce(
      (previousValue, currentValue) => {
        return previousValue + (currentValue.number_of_lots || 0);
      },
      0,
    );
  }

  sellThroughRate() {
    return `${Math.floor(
      (this.numberOfLotsSold() / this.lotsOffered()) * 100,
    )}%`;
  }

  salesOverEstimate() {
    let over =
      (this.auction_performance || []).filter((item) => item.id === "above")[0]
        ?.number_of_lots ?? 0;
    return `${Math.floor((over / this.lotsOffered()) * 100)}%`;
  }
}

export default MarketSignal;
