const url = "/api/v4/referrals";

// Actions

export const GET_REFERRAL_LIST = "GET_REFERRAL_LIST";
export const GET_REFERRAL_LIST_SUCCESS = "GET_REFERRAL_LIST_SUCCESS";
export const GET_REFERRAL_LIST_FAILURE = "GET_REFERRAL_LIST_FAILURE";

export const CREATE_REFERRAL = "CREATE_REFERRAL";
export const CREATE_REFERRAL_SUCCESS = "CREATE_REFERRAL_SUCCESS";
export const CREATE_REFERRAL_FAILURE = "CREATE_REFERRAL_FAILURE";

// Networking

export const getReferralList = () => {
  return {
    types: [
      GET_REFERRAL_LIST,
      GET_REFERRAL_LIST_SUCCESS,
      GET_REFERRAL_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
      },
    },
  };
};

export const createReferral = (data) => {
  return {
    types: [CREATE_REFERRAL, CREATE_REFERRAL_SUCCESS, CREATE_REFERRAL_FAILURE],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};
