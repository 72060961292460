import React, { useState } from 'react';
import { Image, Modal, Button } from 'semantic-ui-react';
import placeholder from '../../../images/profile_placeholder.png';
import SearchGallery from '../../Search/SearchGallery';

const SelectGallery = ({ gallery, onSelectGallery }) => {
  const [currentGallery, setCurrentGallery] = useState(gallery);
  const [modalOpen, setModalOpen] = useState(false);

  const handleSelectGallery = (gallery) => {
    setCurrentGallery(gallery);
    onSelectGallery(gallery);
    setModalOpen(false);
  };

  const handleRemoveGallery = () => {
    setCurrentGallery(undefined);
    onSelectGallery(undefined);
  };

  return (
    <Modal
      trigger={
        <div role="button" onClick={() => setModalOpen(true)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          <Image
            src={currentGallery?.small_image_url || placeholder}
            avatar
          />
          <span style={{ marginRight: '10px' }}>{currentGallery?.name || 'Select Gallery'}</span>
          {currentGallery && (
            <Button icon="remove" onClick={(e) => {
              e.stopPropagation(); // Prevents opening the modal
              handleRemoveGallery();
            }} size="mini" />
          )}
        </div>
      }
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header>Select Gallery</Modal.Header>
      <Modal.Content style={{ minHeight: '425px' }}>
        <SearchGallery onSelectGallery={handleSelectGallery} />
      </Modal.Content>
    </Modal>
  );
};

export default SelectGallery;