import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Header, Menu } from "semantic-ui-react";
import SlidingPane from "react-sliding-pane";

import { connect } from "react-redux";
import BasicInfo from "./BasicInfo";
import Additional from "./Additional";
import Financial from "./Financial";

import Condition from "./Condition";
import Location from "./Location";
import Appraisals from "./Appraisals/Appraisals";
import { createPiece, updatePiece } from "../../../../redux/actions/piece";
import ErrorMessage from "../../../../components/ErrorMessage";
import Piece from "../../../../models/piece";
import { useSearchParams } from "react-router-dom";

const EditPieceSidePain = ({ currentPiece, children, update, create }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [sidePainOpen, setSidePainOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("overview");
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [piece, setPiece] = useState(currentPiece || new Piece());

  const onSubmit = async () => {
    if (updating) return;
    setError(null);
    setUpdating(true);
    try {
      if (currentPiece._id) {
        await update(currentPiece._id, piece.toData());
      } else {
        await create(piece.toData());
        setSearchParams({});
      }
      setSidePainOpen(false);
    } catch (e) {
      setError(e);
    }
    setUpdating(false);
  };

  const onUpdate = (d) => {
    console.log(d);
    setPiece(new Piece({ ...piece, ...d }));
  };

  useEffect(() => {
    setPiece(currentPiece);
  }, [currentPiece]);

  return (
    <>
      <SlidingPane
        overlayClassName="overlay"
        isOpen={sidePainOpen || searchParams.get("onboarding")}
        title={
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header floated={"left"} content={"Edit Piece"} />
                <Button
                  primary
                  content={"Save"}
                  floated={"right"}
                  loading={updating}
                  onClick={onSubmit}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
        subtitle={
          <Menu pointing secondary>
            <Menu.Item
              name="overview"
              active={activeItem === "overview"}
              onClick={(e, { name }) => setActiveItem(name)}
              content="Overview"
            />
            <Menu.Item
              name="financial"
              active={activeItem === "financial"}
              onClick={(e, { name }) => setActiveItem(name)}
              content="Financial"
            />
            <Menu.Item
              name={"additional"}
              active={activeItem === "additional"}
              onClick={(e, { name }) => setActiveItem(name)}
              content={"Additional"}
            />
            <Menu.Item
              name={"location"}
              active={activeItem === "location"}
              onClick={(e, { name }) => setActiveItem(name)}
              content="Location"
            />
            <Menu.Item
              name="appraisals"
              active={activeItem === "appraisals"}
              onClick={(e, { name }) => setActiveItem(name)}
              content="Appraisals"
            />

            <Menu.Item
              name="condition"
              active={activeItem === "condition"}
              onClick={(e, { name }) => setActiveItem(name)}
              content="Condition"
            />
          </Menu>
        }
        onRequestClose={() => setSidePainOpen(false)}
      >
        {error && <ErrorMessage error={error} />}
        {currentPiece && activeItem === "overview" && (
          <BasicInfo piece={piece} onUpdate={onUpdate} />
        )}

        {activeItem === "additional" && (
          <Additional
            piece={piece}
            updating_data={updating}
            onUpdate={onUpdate}
          />
        )}

        {activeItem === "location" && (
          <Location
            piece={piece}
            updating_data={updating}
            onUpdate={onUpdate}
          />
        )}

        {activeItem === "financial" && (
          <Financial
            piece={piece}
            updating_data={updating}
            onUpdate={onUpdate}
          />
        )}
        {activeItem === "appraisals" && (
          <Appraisals
            piece={piece}
            updating_data={updating}
            onUpdate={onUpdate}
          />
        )}

        {activeItem === "condition" && (
          <Condition
            piece={piece}
            updating_data={updating}
            onUpdate={onUpdate}
          />
        )}
      </SlidingPane>

      <div onClick={() => setSidePainOpen(true)}>{children}</div>
    </>
  );
};

EditPieceSidePain.propTypes = {
  user: PropTypes.object,
  currentPiece: PropTypes.object,
};

const mapStateToProps = ({ piece }) => ({});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createPiece(data)),
  update: (id, data) => dispatch(updatePiece(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPieceSidePain);
