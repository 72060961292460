import React, { useState, useEffect } from "react";
import { Button, Grid, Header, Item, Loader, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import BusinessAccount from "./BusinessAccount";
import PremiumAccount from "./PremiumAccount";
import ProAccount from "./ProAccount";
import BasicAccount from "./BasicAccount";
import { getAccountList } from "../../../../../../redux/actions/account";
import EnterpriseAccount from "./EnterpriseAccount";
import ManagedAccount from "./ManagedAccount";

const Billing = ({ user, fetchData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState(null);

  const fetch = async () => {
    setIsLoading(true);
    const response = await fetchData();
    setAccounts(response.payload.data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!user) return;
    fetch();
  }, [user]);

  const account = accounts && accounts[0];

  const accountComponent = () => {
    const isOwner = account.owner._id === user._id;
    switch (user?.account_level) {
      case "basic":
        return isOwner ? (
          <BasicAccount />
        ) : (
          <ManagedAccount account={account} />
        );
      case "premium":
        return isOwner ? (
          <PremiumAccount />
        ) : (
          <ManagedAccount account={account} />
        );
      case "pro":
        return isOwner ? (
          <ProAccount account={account} />
        ) : (
          <ManagedAccount account={account} />
        );
      case "business":
        return isOwner ? (
          <BusinessAccount account={account} />
        ) : (
          <ManagedAccount account={account} />
        );
      case "enterprise":
        return account.roles[user._id] !== "user" ? (
          <EnterpriseAccount account={account} />
        ) : (
          <ManagedAccount account={account} />
        );
      default:
        return null;
    }
  };

  if (!user) return null;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header
            size={"huge"}
            content={"Billing"}
            subheader={`Account-ID: ${account && account._id}`}
          />
        </Grid.Column>
      </Grid.Row>
      {account ? accountComponent() : <Loader active />}
    </Grid>
  );
};

const mapStateToProps = ({ firebase }) => ({
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: () => dispatch(getAccountList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
