import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Header, Table } from "semantic-ui-react";

import {
  getAuctionList,
  getAuctionsWithFilter,
} from "../../../../redux/actions/auction";
import InfiniteScroll from "react-infinite-scroller";
import ErrorMessage from "../../../../components/ErrorMessage";
import AdminAuctionTile from "../AdminAuctionTile";
import AuthLayout from "../../../../layouts/AuthLayout";
import UpcomingAuctionsList from "../UpcomingAuctionsList";

const AdminAuctionsList = ({
  auctions,
  fetchAll,
  fetchUpcoming,
  error,
  hasMoreItems,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [futureAuctions, setFutureAuctions] = useState(null);

  const fetch = async () => {
    setIsLoading(true);
    try {
      const { payload } = await fetchUpcoming();
      setFutureAuctions(payload.data);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  const items = auctions.map((auction) => (
    <AdminAuctionTile key={auction._id} auction={auction} />
  ));

  return (
    <AuthLayout title={"Auctions"} bannersEnabled={true}>
      <Grid stackable>
        <Grid.Row verticalAlign={"middle"}>
          <Grid.Column width={16}>
            <Header size="huge" content="Upcoming Auctions" />
            {error && <ErrorMessage error={error} />}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <UpcomingAuctionsList auctions={futureAuctions} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <InfiniteScroll
              initialLoad={true}
              loadMore={(page) => fetchAll(page)}
              hasMore={hasMoreItems}
              loader={
                <Header
                  key={"loader"}
                  textAlign={"center"}
                  size={"small"}
                  content={"Loading ..."}
                />
              }
            >
              <Table striped selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell key={"name"} content={"Name"} />
                    <Table.HeaderCell
                      key={"auction_house"}
                      content={"Auction House"}
                    />
                    <Table.HeaderCell key={"date"} content={"Date"} />
                    <Table.HeaderCell
                      key={"user_reach"}
                      content={"User Reach"}
                    />
                    <Table.HeaderCell key={"status"} content={"Status"} />
                  </Table.Row>
                </Table.Header>
                <Table.Body>{items}</Table.Body>
              </Table>
            </InfiniteScroll>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ firebase, auctions }) => ({
  user: firebase.profile || null,
  auctions: auctions.auctions,
  error: auctions.error,
  hasMoreItems: auctions.hasMoreAuctions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAll: (page) => dispatch(getAuctionList(page)),
  fetchUpcoming: () => dispatch(getAuctionsWithFilter("upcoming")),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminAuctionsList);
