import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Header, Image, Segment, Table } from "semantic-ui-react";
import { getAuctionResultList } from "../../../../../redux/actions/auction_result";
import placeholder from "../../../../../images/placeholder.png";
import AuctionRecord from "../../../../../models/auction_record";
import moment from "moment";
import {
  AUCTION_PATH,
  AUCTION_RECORD_PATH,
} from "../../../../../constants/paths";
import { useNavigate } from "react-router-dom";

const PastLots = ({ fetchAll, user, currencies }) => {
  const navigate = useNavigate();
  const [auctionRecords, setAuctionRecords] = useState(null);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const response = await fetchAll();
    setAuctionRecords(
      response.payload.data.map((item) => new AuctionRecord(item)),
    );
  };

  const items = (auctionRecords || []).map((auctionRecord) => (
    <Table.Row key={auctionRecord._id} style={{ cursor: "pointer" }}>
      <Table.Cell
        onClick={() => navigate(`${AUCTION_RECORD_PATH}/${auctionRecord._id}`)}
      >
        <Image src={auctionRecord.small_image_url || placeholder} size="mini" />
      </Table.Cell>

      <Table.Cell
        onClick={() => navigate(`${AUCTION_RECORD_PATH}/${auctionRecord._id}`)}
      >
        <Header as="h4">
          <Header.Content>
            {auctionRecord.title}
            <Header.Subheader
              content={auctionRecord.artist && auctionRecord.artist.name}
            />
          </Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell
        onClick={
          auctionRecord.auction
            ? () => navigate(`${AUCTION_PATH}/${auctionRecord.auction._id}`)
            : null
        }
      >
        <Header
          size="tiny"
          content={
            auctionRecord.auction &&
            auctionRecord.auction.auction_house &&
            auctionRecord.auction.auction_house.name
          }
          subheader={`${moment(auctionRecord.auction.end_date).format(
            "MMM D, YYYY",
          )}`}
        />
      </Table.Cell>
      <Table.Cell
        content={auctionRecord.estimatePriceString(currencies, user)}
        onClick={() => navigate(`${AUCTION_RECORD_PATH}/${auctionRecord._id}`)}
      />
      <Table.Cell
        onClick={() => navigate(`${AUCTION_RECORD_PATH}/${auctionRecord._id}`)}
      >
        {auctionRecord.pricePercentageChangeString() && (
          <Header size="tiny">
            <Header.Content
              content={auctionRecord.hammerPriceString(currencies, user)}
            />
            {auctionRecord.pricePercentageChange() > 0 && (
              <Header.Subheader
                style={{ color: "green" }}
                content={auctionRecord.pricePercentageChangeString()}
              />
            )}
            {auctionRecord.pricePercentageChange() < 0 && (
              <Header.Subheader
                style={{ color: "red" }}
                content={auctionRecord.pricePercentageChangeString()}
              />
            )}
          </Header>
        )}
        {!auctionRecord.pricePercentageChangeString() && (
          <Header size="tiny" content={"-"} />
        )}
      </Table.Cell>
    </Table.Row>
  ));

  if (!items || items.length === 0) return null;

  return (
    <Grid stackable style={{ padding: 0 }}>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header content={"Past Lots You May Be Interested In"} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16} style={{ padding: 0 }}>
          <Segment basic>
            <Table striped selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1} />
                  <Table.HeaderCell width={8} content={"Name"} />
                  <Table.HeaderCell content={"Auction House"} />
                  <Table.HeaderCell content={"Estimate"} />
                  <Table.HeaderCell content={"Hammer"} />
                </Table.Row>
              </Table.Header>
              <Table.Body>{items}</Table.Body>
            </Table>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = ({ firebase, currencies }) => ({
  user: firebase.profile || null,
  currencies: currencies.currencies,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAll: () => dispatch(getAuctionResultList(1, 10, "my_artists", "sold")),
});

export default connect(mapStateToProps, mapDispatchToProps)(PastLots);
