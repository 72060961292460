import React, { Component } from "react";
import { Form, Header, Grid } from "semantic-ui-react";

import { currencyOptions } from "../../common";
import SelectGalleryModal from "../Modals/Gallery/Select";
import FormDatePicker from "./FormDatePicker";
import PurchaseAttachments from "../../routes/Pieces/components/EditPieceSidePane/Attachments/PurchaseAttachments";

const PurchaseInfoForm = ({ piece, onUpdate }) => {
  const onSelectGallery = (gallery) => onUpdate({ gallery });
  const handleChange = (e, { name, value }) => onUpdate({ [name]: value });
  const handleDayChange = (day, modifiers, { props }) =>
    onUpdate({ [props.name]: day });

  const {
    gallery,
    purchase_date,
    currency,
    purchase_price,
    valuation_currency,
    valuation_price,
    purchase_notes,
    purchase_attachments,
  } = piece;

  return (
    <Form>
      <Form.Group>
        <Header size="large">Purchase Details</Header>
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <label>Purchase Location</label>
        </Form.Field>
        <Form.Field width={12}>
          <SelectGalleryModal
            gallery={gallery}
            onSelectGallery={onSelectGallery}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <label>Purchase Date</label>
        </Form.Field>
        <Form.Field width={12}>
          <FormDatePicker
            fluid
            name="purchase_date"
            placeholder="Purchase Date"
            value={purchase_date}
            autoComplete={"off"}
            handleDayChange={handleDayChange}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <label>Purchase Price</label>
        </Form.Field>
        <Form.Select
          fluid
          name="currency"
          placeholder="$"
          defaultValue={currency || "USD"}
          options={currencyOptions}
          autoComplete={"off"}
          onChange={handleChange}
          search
          width={2}
        />
        <Form.Input
          fluid
          name="purchase_price"
          placeholder="Purchase Price"
          defaultValue={purchase_price}
          type="number"
          step="0.01"
          autoComplete={"off"}
          onChange={handleChange}
          width={10}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <label>Valuation Price</label>
        </Form.Field>
        <Form.Select
          fluid
          name="valuation_currency"
          placeholder="$"
          defaultValue={valuation_currency || "USD"}
          options={currencyOptions}
          autoComplete={"off"}
          onChange={handleChange}
          search
          width={2}
        />
        <Form.Input
          fluid
          name="valuation_price"
          placeholder="Valuation Price"
          defaultValue={valuation_price}
          type="number"
          step="0.01"
          autoComplete={"off"}
          onChange={handleChange}
          width={10}
        />
      </Form.Group>
      <PurchaseAttachments
        attachments={purchase_attachments}
        onAttachmentsUpdated={(attachments) =>
          onUpdate({ purchase_attachments: attachments })
        }
      />
    </Form>
  );
};
export default PurchaseInfoForm;
