const options = [
  { key: 0.0, text: "0", value: 0 },
  { key: 0.125, text: "⅛", value: 0.125 },
  { key: 0.1667, text: "⅙", value: 0.1667 },
  { key: 0.1875, text: "3/16", value: 0.1875 },
  { key: 0.25, text: "¼", value: 0.25 },
  { key: 0.3125, text: "5/16", value: 0.3125 },
  { key: 0.375, text: "⅜", value: 0.375 },
  { key: 0.4375, text: "7/16", value: 0.4375 },
  { key: 0.5, text: "½", value: 0.5 },
  { key: 0.5625, text: "9/16", value: 0.5625 },
  { key: 0.625, text: "⅝", value: 0.625 },
  { key: 0.6875, text: "11/16", value: 0.6875 },
  { key: 0.75, text: "¾", value: 0.75 },
  { key: 0.8125, text: "13/16", value: 0.8125 },
  { key: 0.875, text: "⅞", value: 0.875 },
  { key: 0.9375, text: "15/16", value: 0.9375 },
];

const measurement = (value) => {
  const total = parseFloat(value);
  const whole = Math.floor(total);
  const fraction = parseFloat((total - whole).toFixed(4));
  return [whole, fraction];
};

const sizeString = (width, height, depth, measurement_units) => {
  if (!width && !height && !depth) {
    return null;
  }

  const formatDimension = (dimension) => {
    if (measurement_units === "metric") return dimension.toString();

    const [whole, fraction] = measurement(dimension);
    const option = options.find((o) => Math.abs(o.key - fraction) < 0.001);

    return option && option.text !== "0"
      ? `${whole} ${option.text}`
      : `${whole}`;
  };

  let string = "";

  if (height) string += formatDimension(height);
  if (width) string += `${string ? " x " : ""}${formatDimension(width)}`;
  if (depth) string += `${string ? " x " : ""}${formatDimension(depth)}`;

  if (string) string += measurement_units === "metric" ? " cm" : " in.";

  return string;
};

const priceString = (price, currency = "USD", user, currencies) => {
  if (!price) return null;

  const formatCurrency = (value, currencyCode) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 2,
    }).format(value);

  if (user && currencies && user.preferred_currency !== currency) {
    const currencyMap = Object.fromEntries(
      currencies.map((c) => [c.symbol, c.value])
    );

    const baseCurrencyRate = currencyMap[currency];
    const targetCurrencyRate = currencyMap[user.preferred_currency];

    if (baseCurrencyRate && targetCurrencyRate) {
      const convertedPrice = (price / baseCurrencyRate) * targetCurrencyRate;
      return formatCurrency(convertedPrice, user.preferred_currency);
    }
  }

  return formatCurrency(price, currency.toUpperCase());
};

const estimateString = (low, high, currency, user, currencies) => {
  if (!low && !high) return "Estimate Upon Request";

  const formatCurrency = (value, currencyCode) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 2,
    }).format(value);

  if (user && currencies && user.preferred_currency !== currency) {
    const currencyMap = Object.fromEntries(
      currencies.map((c) => [c.symbol, c.value])
    );

    const baseCurrencyRate = currencyMap[currency];
    const targetCurrencyRate = currencyMap[user.preferred_currency];

    if (baseCurrencyRate && targetCurrencyRate) {
      const convertedLow = (low / baseCurrencyRate) * targetCurrencyRate;
      const convertedHigh = (high / baseCurrencyRate) * targetCurrencyRate;
      return `${formatCurrency(convertedLow, user.preferred_currency)} - ${formatCurrency(
        convertedHigh,
        user.preferred_currency
      )}`;
    }
  }

  return `${formatCurrency(low, currency)} - ${formatCurrency(high, currency)}`;
};

const editionString = (edition_number, edition_size) => {
  if (!edition_number && !edition_size) return null;

  if (!edition_number) return `Edition of ${edition_size}`;
  if (!edition_size) return edition_number;

  return `${edition_number} / ${edition_size}`;
};

export { sizeString, priceString, estimateString, editionString };