import React from "react";
import { Image, Table } from "semantic-ui-react";
import placeholder from "../../../../../../images/placeholder.png";
import {
  ARTIST_PATH,
  AUCTION_RECORD_PATH,
} from "../../../../../../constants/paths";
import { useNavigate } from "react-router-dom";

const AuctionAuctionRecordTile = ({ user, currencies, auctionRecord }) => {
  const navigate = useNavigate();

  const onRowClick = (e, url) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  return (
    <Table.Row key={auctionRecord._id} style={{ cursor: "pointer" }}>
      <Table.Cell
        onClick={(e) =>
          onRowClick(e, `${AUCTION_RECORD_PATH}/${auctionRecord._id}`)
        }
      >
        {auctionRecord.lot_number}
      </Table.Cell>
      <Table.Cell
        onClick={(e) =>
          onRowClick(e, `${AUCTION_RECORD_PATH}/${auctionRecord._id}`)
        }
      >
        <Image avatar src={auctionRecord.small_image_url || placeholder} />
        {auctionRecord.title}
      </Table.Cell>
      <Table.Cell
        onClick={(e) =>
          onRowClick(e, `${ARTIST_PATH}/${auctionRecord.artist._id}`)
        }
      >
        {auctionRecord.artist && auctionRecord.artist.name}
      </Table.Cell>
      <Table.Cell
        content={auctionRecord.estimatePriceString(currencies, user)}
        onClick={(e) =>
          onRowClick(e, `${AUCTION_RECORD_PATH}/${auctionRecord._id}`)
        }
      />
      <Table.Cell
        content={auctionRecord.hammerPriceString(currencies, user)}
        onClick={(e) =>
          onRowClick(e, `${AUCTION_RECORD_PATH}/${auctionRecord._id}`)
        }
      />
    </Table.Row>
  );
};

export default AuctionAuctionRecordTile;
