import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Grid, Header, Image, List, Divider } from "semantic-ui-react";
import placeholder from "../../../../../../../../images/placeholder.png";
import { capitalize } from "lodash";
import { ARTIST_PATH } from "../../../../../../../../constants/paths";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const Cell = ({ header, content, attachments, image, link }) => {
  const onAttachmentClicked = async (attachment) => {
    if (attachment.file_reference) {
      try {
        const storage = getStorage();
        const url = await getDownloadURL(
          ref(storage, attachment.file_reference),
        );
        window.open(url, "_blank");
      } catch (e) {
        console.error(e);
      }
    } else {
      window.open(attachment.url, "_blank");
    }
  };

  return (
    <Grid.Column>
      <List>
        <List.Item>
          <List.Content>
            <Header size="small" content={header} />
            {!link && (
              <List.Description>
                {image && <Image src={image || placeholder} avatar />}
                {content && content}
              </List.Description>
            )}
            {link && (
              <List.Description as={Link} to={{ pathname: link }}>
                {image && <Image src={image || placeholder} avatar />}
                {content || "N/A"}
              </List.Description>
            )}
            {attachments && attachments.length > 0 && (
              <List.Description>
                <List bulleted>
                  {attachments.map((attachment) => (
                    <List.Item
                      as={"a"}
                      key={attachment._id}
                      content={attachment.name}
                      onClick={() => onAttachmentClicked(attachment)}
                    />
                  ))}
                </List>
              </List.Description>
            )}
          </List.Content>
        </List.Item>
      </List>
    </Grid.Column>
  );
};

const PieceInformation = ({ piece }) => {
  const {
    inventory_identifier,
    date,
    category,
    medium,
    artist,
    description,
    is_framed,
    is_signed,
    dimensionString,
    editionString,
    framedDimensionString,
    framing_attachments,
  } = piece;

  return (
    <>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header content={"Information"} dividing />
          <Cell header={"Title"} content={piece.title ?? "Untitled"} />
          {date && <Cell header={"Date"} content={date} />}
          {inventory_identifier && (
            <Cell header={"Inventory ID"} content={inventory_identifier} />
          )}
          {artist && (
            <Cell
              header={"Artist"}
              content={artist && artist.name}
              image={artist && artist.small_image_url}
              link={artist && `${ARTIST_PATH}/${artist._id}`}
            />
          )}
          {category && <Cell header={"Type"} content={capitalize(category)} />}
          {dimensionString && (
            <Cell header={"Dimensions"} content={dimensionString} />
          )}
          {is_framed &&
            (framedDimensionString || framing_attachments.length > 0) && (
              <Cell
                header={"Framed Dimensions"}
                content={framedDimensionString}
                attachments={framing_attachments}
              />
            )}
          {editionString && (
            <Cell header={"Edition"} content={piece.editionString} />
          )}
          {is_signed && <Cell header={"Signed"} content={"Yes"} />}

          {medium && <Cell header={"Medium"} content={medium} />}
          {description && <Cell header={"Description"} content={description} />}
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

PieceInformation.propTypes = {
  piece: PropTypes.object,
};

export default PieceInformation;
