import React from "react";
import { connect, useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { useFirestoreConnect } from "react-redux-firebase";
import { createTag } from "../../redux/actions/tag";

const TagFormField = ({
  defaultValue,
  auth,
  onChange,
  width,
  create,
  user,
}) => {
  useFirestoreConnect([
    {
      orderBy: ["name", "asc"],
      collection: "tags",
      doc: (user && user.current_user_id) || auth.uid,
      subcollections: [{ collection: "tags" }],
      storeAs: "tags",
    },
  ]);

  const tags = useSelector((state) => state.firestore.ordered.tags);

  const onAddItem = async (e, { value }) => {
    try {
      await create({ name: value });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Form.Select
      width={width}
      placeholder={"Select Tags"}
      search
      multiple
      defaultValue={defaultValue}
      allowAdditions
      onAddItem={onAddItem}
      onChange={(e, { value }) =>
        onChange(tags.filter((tag) => value.includes(tag.id)))
      }
      options={(tags || []).map((tag) => ({
        key: tag.id || tag._id,
        value: tag.id || tag._id,
        text: tag.name,
      }))}
    />
  );
};

const mapStateToProps = ({ firebase }) => ({
  auth: firebase.auth || null,
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createTag(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagFormField);
