import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Grid, Header, Input, Menu, Segment } from "semantic-ui-react";
import PiecesTable from "../PiecesTable";
import {
  createPiece,
  setOrderBy,
  setPieceListFilters,
  togglePieceListDisplay,
} from "../../../../redux/actions/piece";
import PiecesGrid from "../PiecesGrid";
import { Mixpanel } from "../../../../utils/Mixpanel";
import AuthLayout from "../../../../layouts/AuthLayout";
import Piece from "../../../../models/piece";
import ExportModal from "../../../../components/Modals/ExportModal";
import PieceFields from "../PieceFilters";
import EditPieceSidePane from "../EditPieceSidePane";
import { useNavigate } from "react-router-dom";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import TagMenu from "../TagMenu/TagMenu";
import SelectPiecesForTagModal from "./components/SelectPiecesForTagModal";
import DashboardModal from "../../../../components/Modals/DashboardModal";
import { sortPieces } from "../../../../utils/sort";
import EditMultiplePiecesModal from "../../../../components/Modals/EditMultiplePiecesModal";
import ErrorMessage from "../../../../components/ErrorMessage";

const PiecesList = ({
  auth,
  display,
  toggleDisplay,
  user,
  changeOrderBy,
  orderBy,
  activeTab,
}) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedPieceIDs, setSelectedPieceIDs] = useState(new Set());

  useFirestoreConnect([
    {
      collection: "pieces",
      doc: (user && user.current_user_id) || auth.uid,
      where:
        activeTab !== "all" ? [["tagIDs", "array-contains", activeTab]] : null,
      subcollections: [
        {
          collection: "pieces",
        },
      ],
      storeAs: "pieces",
      orderBy: orderBy,
    },
  ]);

  const pieceData = useSelector((state) => state.firestore.ordered.pieces);
  const error = useSelector((state) => state.firestore.errors.byQuery.pieces);
  const pieces = (pieceData || []).map((data) => new Piece(data));

  useEffect(() => {
    Mixpanel.page("piece");
  }, []);

  const onSearchChanged = (e, { value }) => {
    setSearchTerm(value);
  };

  const handSelectAllPieces = (checked) => {
    if (checked) {
      const pieceIDs = sortPieces(
        !searchTerm || searchTerm.length === 0
          ? pieces
          : pieces.filter(
              (piece) =>
                piece.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (
                  (piece.artist && piece.artist.name.toLowerCase()) ??
                  ""
                ).includes(searchTerm.toLowerCase()) ||
                (
                  (piece.gallery && piece.gallery.name.toLowerCase()) ??
                  ""
                ).includes(searchTerm.toLowerCase()) ||
                (
                  (piece.inventory_identifier &&
                    piece.inventory_identifier.toLowerCase()) ??
                  ""
                ).includes(searchTerm.toLowerCase()),
            ),
        orderBy,
      ).map((piece) => piece._id);
      setSelectedPieceIDs(new Set(pieceIDs));
    } else {
      setSelectedPieceIDs(new Set());
    }
  };

  const handleSelectPieceID = (pieceID) => {
    if (selectedPieceIDs.has(pieceID)) {
      selectedPieceIDs.delete(pieceID);
    } else {
      selectedPieceIDs.add(pieceID);
    }
    setSelectedPieceIDs(new Set(selectedPieceIDs));
  };

  return (
    <AuthLayout title={"Artworks"} bannersEnabled={true}>
      {error && <ErrorMessage error={error} />}
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={10}>
            <Segment basic>
              <Header size="huge" content="Artworks" />
            </Segment>
          </Grid.Column>
          <Grid.Column width={6} floated={"right"}>
            <Segment basic>
              <Button
                icon={"upload"}
                content={"Import Artwork"}
                labelPosition={"left"}
                floated={"right"}
                onClick={() => navigate("/import")}
              />
              <EditPieceSidePane
                currentPiece={
                  new Piece({
                    tags: activeTab === "all" ? [] : [activeTab],
                  })
                }
              >
                <Button
                  color={"black"}
                  icon={"plus"}
                  content={"New Artwork"}
                  labelPosition={"left"}
                  floated={"right"}
                />
              </EditPieceSidePane>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <TagMenu />
            <Menu secondary>
              <Menu.Item style={{ paddingLeft: 0 }}>
                <EditMultiplePiecesModal
                  currentSelectedPieces={selectedPieceIDs}
                />
              </Menu.Item>
              <Menu.Item style={{ flexGrow: 2, paddingLeft: 0 }}>
                <Input
                  icon={"search"}
                  placeholder={"Search..."}
                  onChange={onSearchChanged}
                />
              </Menu.Item>
              <Menu.Menu position={"right"}>
                <DashboardModal pieces={pieces} />
                <Menu.Item
                  icon={display === "grid" ? "list" : "grid layout"}
                  onClick={() =>
                    toggleDisplay(display === "grid" ? "table" : "grid")
                  }
                />
                <Menu.Item style={{ paddingRight: 0 }}>
                  <div>
                    <ExportModal
                      selectedPiece
                      currentPieces={pieces}
                      currentSelectedPieces={selectedPieceIDs}
                    />
                    <PieceFields />
                  </div>
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment
              basic
              style={{ padding: 0 }}
              loading={!isLoaded(pieceData)}
            >
              {display === "table" && (
                <PiecesTable
                  orderBy={orderBy}
                  handleOrderByChange={(key) => changeOrderBy(key)}
                  selectedPieceIDs={selectedPieceIDs}
                  handSelectAllPieces={handSelectAllPieces}
                  handleSelectPieceID={handleSelectPieceID}
                  pieces={sortPieces(
                    !searchTerm || searchTerm.length === 0
                      ? pieces
                      : pieces.filter(
                          (piece) =>
                            piece.title
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.artist &&
                                piece.artist.name.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.gallery &&
                                piece.gallery.name.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.location &&
                                piece.location.name.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.inventory_identifier &&
                                piece.inventory_identifier.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()),
                        ),
                    orderBy,
                  )}
                />
              )}
              {display === "grid" && (
                <PiecesGrid
                  pieces={sortPieces(
                    !searchTerm || searchTerm.length === 0
                      ? pieces
                      : pieces.filter(
                          (piece) =>
                            piece.title
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.artist &&
                                piece.artist.name.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.gallery &&
                                piece.gallery.name.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.location &&
                                piece.location.name.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()) ||
                            (
                              (piece.inventory_identifier &&
                                piece.inventory_identifier.toLowerCase()) ??
                              ""
                            ).includes(searchTerm.toLowerCase()),
                        ),
                    orderBy,
                  )}
                />
              )}
              {activeTab._id !== "all" && (
                <SelectPiecesForTagModal
                  tag={activeTab}
                  currentSelectedPieces={(pieces || []).map(
                    (piece) => piece.id,
                  )}
                />
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ pieces, firebase }) => ({
  auth: firebase.auth || null,
  user: firebase.profile || null,
  orderBy: pieces.orderBy,
  display: pieces.display,
  activeTab: pieces.activeTab || "all",
});

const mapDispatchToProps = (dispatch) => ({
  toggleDisplay: (name) => dispatch(togglePieceListDisplay(name)),
  create: (data) => dispatch(createPiece(data)),
  changeOrderBy: (data) => dispatch(setOrderBy(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PiecesList);
