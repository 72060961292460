import React, { useState } from "react";
import {
  Card,
  Grid,
  Header,
  Menu,
  Modal,
  Segment,
  Statistic,
} from "semantic-ui-react";
import { connect } from "react-redux";
import PieChart from "./PieChart";

const DashboardModal = ({ pieces, user }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: user.preferred_currency || "USD",
    minimumFractionDigits: 2,
  });

  const cost = pieces.reduce((sum, piece) => {
    const updatedSum = sum + (piece.purchase_price || 0);
    return updatedSum;
  }, 0);

  const valuation = pieces.reduce((sum, piece) => {
    const updatedSum =
      sum + (piece.valuation_price || piece.purchase_price || 0);
    return updatedSum;
  }, 0);

  return (
    <Modal
      trigger={
        <Menu.Item icon={"pie graph"} onClick={() => setModalOpen(true)} />
      }
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={() => setModalOpen(false)}
      closeIcon
    >
      <Modal.Header content={"Overview"} />
      <Modal.Content>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Segment textAlign={"center"}>
                <Statistic
                  textAlign={"center"}
                  size={"small"}
                  label={"Amount Spent"}
                  value={formatter.format(cost)}
                />
              </Segment>
              <Segment textAlign={"center"}>
                <Statistic
                  textAlign={"center"}
                  size={"small"}
                  label={"Valuation"}
                  value={formatter.format(valuation)}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <PieChart pieces={pieces} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};
const mapStateToProps = ({ firebase }) => ({
  user: firebase.profile || null,
});

export default connect(mapStateToProps)(DashboardModal);
