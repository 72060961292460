import React, { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Breadcrumb, Button, Confirm, Grid, Header, Segment } from "semantic-ui-react";
import { deletePiece } from "../../../../../../redux/actions/piece";
import { isLoaded, isEmpty, useFirestoreConnect } from "react-redux-firebase";
import AuthLayout from "../../../../../../layouts/AuthLayout";
import ImageModal from "../../../../../../components/Modals/Image/Modal";
import ExportPiecePDFModal from "../../../../../../components/Modals/ExportPiecePDFModal/ExportPiecePDFModal";
import EditPieceSidePane from "../../../../components/EditPieceSidePane";
import EmptyState from "../../../../../../components/EmptyState";
import { DASHBOARD_PATH, PIECE_PATH } from "../../../../../../constants/paths";
import Piece from "../../../../../../models/piece";
import { searchClient } from "../../../../../../utils/algolia";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PieceImages from "./components/PieceImages/PieceImages";
import Information from "./components/Information";
import Location from "./components/Location";
import Acquisition from "./components/Acquisition";
import Valuation from "./components/Valuation";
import Provenance from "./components/Provenance";
import Publications from "./components/Publications";
import Exhibitions from "./components/Exhibitions";
import Files from "./components/Files";
import Appraisals from "./components/Appraisals";
import Notes from "./components/Notes";
import Condition from "./components/Condition/Condition";

const PieceDetails = () => {
  const { pieceId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [imageModal, setImageModal] = useState(null);
  
  const userID = useSelector(
    (state) => state.firebase.profile?.current_user_id || state.firebase.auth.uid
  );

  useFirestoreConnect([
    {
      collection: "pieces",
      doc: userID,
      subcollections: [{ collection: "pieces", doc: pieceId }],
      storeAs: pieceId,
    },
  ]);

  const pieceData = useSelector(
    ({ firestore: { ordered } }) => ordered[pieceId]
  );

  const handleDelete = async () => {
    await dispatch(deletePiece(pieceId));
    setConfirmOpen(false);
    await searchClient.clearCache();
    navigate(DASHBOARD_PATH);
  };

  if (!isLoaded(pieceData)) {
    return (
      <AuthLayout title="Loading...">
        <Segment basic loading style={{ height: "100vh" }} />
      </AuthLayout>
    );
  }

  if (isEmpty(pieceData)) {
    return (
      <AuthLayout title="Not Found">
        <EmptyState
          title="Not Found"
          detail="There is no piece in your collection associated with this ID."
        />
      </AuthLayout>
    );
  }

  const piece = pieceData && new Piece(pieceData[0]);

  return (
    <AuthLayout
      title={piece?.title || "Loading..."}
      description={piece?.description?.slice(0, 100)}
    >
      {piece?.images?.map((image) => (
        <ImageModal
          key={image._id}
          image={image}
          open={imageModal === image._id}
          handleClose={() => setImageModal(null)}
        />
      ))}

      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Breadcrumb>
              <Breadcrumb.Section as={Link} to={DASHBOARD_PATH}>
                Home
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
              <Breadcrumb.Section as={Link} to={PIECE_PATH}>
                Pieces
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
              <Breadcrumb.Section active>
                {piece?.title || "Loading..."}
              </Breadcrumb.Section>
            </Breadcrumb>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={2}>
          <Grid.Column>
            <Header size="huge" content={piece?.title} />
          </Grid.Column>
          <Grid.Column>
            <Button basic floated="right" content="Delete Artwork" icon="close" labelPosition="left" onClick={() => setConfirmOpen(true)} />
            <Confirm open={confirmOpen} onCancel={() => setConfirmOpen(false)} onConfirm={handleDelete} content="Are you sure you want to delete this piece and all of its associated records?" />
            <ExportPiecePDFModal piece={piece} />
            <EditPieceSidePane currentPiece={piece}>
              <Button basic icon="edit" floated="right" content="Edit Artwork" labelPosition="left" />
            </EditPieceSidePane>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          {piece?.images?.length > 0 && (
            <Grid.Column width={8} textAlign="center">
              <Segment basic>
                <PieceImages piece={piece} onImageSelected={setImageModal} />
              </Segment>
            </Grid.Column>
          )}
          <Grid.Column width={piece?.images?.length > 0 ? 8 : 16}>
            <Grid>
              <Information piece={piece} />
              <Location piece={piece} />
              <Acquisition piece={piece} />
              <Valuation piece={piece} />
              <Provenance piece={piece} />
              <Publications piece={piece} />
              <Exhibitions piece={piece} />
              <Files piece={piece} />
              <Appraisals piece={piece} />
              <Notes piece={piece} />
              <Condition piece={piece} />
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

export default PieceDetails;