import React from "react";
import { Divider, Form, Grid, Header } from "semantic-ui-react";
import InsuranceAttachments from "../Attachments/InsuranceAttachments";
import PurchaseInfoForm from "../../../../../components/Forms/PurchaseInfoForm";
import Insurance from "../Insurance";
import PurchaseAttachments from "../Attachments/PurchaseAttachments";

const Financial = ({ piece, onUpdate }) => {
  const onSelectGallery = (gallery) => onUpdate({ gallery });
  const handleChange = (e, { name, value }) => onUpdate({ [name]: value });
  const handleDayChange = (day, modifiers, { props }) =>
    onUpdate({ [props.name]: day });

  return (
    <Grid>
      <Grid.Row columns={1}>
        <Grid.Column>
          <PurchaseInfoForm
            piece={piece}
            onSelectGallery={onSelectGallery}
            handleChange={handleChange}
            handleDayChange={handleDayChange}
            onUpdate={onUpdate}
          />
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Grid.Row columns={1}>
        <Grid.Column>
          <Insurance piece={piece} onUpdate={onUpdate} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Financial;
