import React, { Component, useRef, useState } from "react";
import { Button, Form, Grid, Header, Modal } from "semantic-ui-react";
import {
  createTwoFactorSetting,
  verifyTwoFactorSetting,
} from "../../../../../../../redux/actions/two_factor_setting";
import { connect } from "react-redux";
import ErrorMessage from "../../../../../../../components/ErrorMessage";
import { auth } from "../../../../../../../utils/firebase";
import firebase from "firebase/compat/app";

const State = {
  Enroll: "enroll",
  Password: "password",
  Code: "code",
};

const CreateTwoFactorSetting = ({ userHasUpdatedEnrollment }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [code, setCode] = useState(null);
  const [state, setState] = useState(State.Enroll);

  const [password, setPassword] = useState(null);
  const [verificationId, setVerificationId] = useState(null);
  const captchaRef = useRef(null);

  const onCaptcha = () => {};

  const onSubmitPassword = async () => {
    setError(null);
    setLoading(true);

    try {
      const email = await auth.currentUser.email;
      await auth.signInWithEmailAndPassword(email, password);
      setState(State.Code);
      await onSubmit();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    try {
      if (verificationId) {
        const credential = firebase.auth.PhoneAuthProvider.credential(
          verificationId,
          code,
        );
        const multiFactorAssertion =
          firebase.auth.PhoneMultiFactorGenerator.assertion(credential);
        await auth.currentUser.multiFactor.enroll(
          multiFactorAssertion,
          "My personal phone number",
        );
        setModalOpen(false);
        userHasUpdatedEnrollment();
      } else {
        const session = await auth.currentUser.multiFactor.getSession();
        const phoneInfoOptions = { phoneNumber, session };
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          captchaRef.current,
          {
            size: "invisible",
            callback: (response) => {
              onCaptcha();
            },
          },
        );
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier,
        );
        setVerificationId(verificationId);
        setState(State.Code);
      }
    } catch (e) {
      switch (e.code) {
        case "auth/requires-recent-login":
          setState(State.Password);
          break;
        default:
          setError(e);
          break;
      }
    }

    setLoading(false);
  };

  const renderBody = () => {
    switch (state) {
      case State.Code:
        return (
          <>
            <Header content={"Please enter the code we just sent via SMS"} />
            <Form>
              <Form.Input
                key={"code"}
                placeholder={"Code"}
                autoComplete={"off"}
                defaultValue={code}
                onChange={(e, { value }) => setCode(value)}
              />
            </Form>
          </>
        );
      case State.Password:
        return (
          <>
            <Header content={"Please verify password"} />
            <Form>
              <Form.Input
                label="Password"
                type="password"
                placeholder="Password"
                onChange={(e, { value }) => setPassword(value)}
              />
            </Form>
          </>
        );
      case State.Enroll:
        return (
          <>
            <Header
              content={"Enter your mobile phone number"}
              subheader={
                "We will use this number to send you a text message with a confirmation code."
              }
            />
            <Form>
              <Form.Input
                key={"phone"}
                placeholder={"Phone Number"}
                autoComplete={"off"}
                defaultValue={phoneNumber}
                onChange={(e, { value }) => setPhoneNumber(value)}
              />
            </Form>
          </>
        );
    }
  };

  const renderButtons = () => {
    switch (state) {
      case State.Code:
        return (
          <Button
            positive
            loading={loading}
            content={"Done"}
            onClick={onSubmit}
          />
        );
      case State.Password:
        return (
          <Button
            primary
            loading={loading}
            content={"Verify"}
            onClick={onSubmitPassword}
          />
        );
      case State.Enroll:
        return (
          <Button
            primary
            loading={loading}
            content={"Next"}
            onClick={onSubmit}
          />
        );
    }
  };

  return (
    <Modal
      open={modalOpen}
      trigger={
        <Button
          floated={"right"}
          icon={"plus"}
          content={"Add step"}
          onClick={() => setModalOpen(true)}
        />
      }
    >
      <Modal.Header
        size="huge"
        content={"Add Two Factor Authentication: SMS"}
      />
      <Modal.Content>
        {error && <ErrorMessage error={error} />}
        <div id="recaptcha-container" ref={captchaRef} />
        {renderBody()}
      </Modal.Content>
      <Modal.Actions>
        <Button content="Close" onClick={() => setModalOpen(false)} />
        {renderButtons()}
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = ({ firebase }) => ({
  user: firebase.auth || null,
});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createTwoFactorSetting(data)),
  verify: (id, data) => dispatch(verifyTwoFactorSetting(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateTwoFactorSetting);
