import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Header, Segment, Statistic } from "semantic-ui-react";
import { getMarketSignals } from "../../../../../../redux/actions/artist";
import MarketSignal from "../../../../../../models/market_signal";

const ArtistMarketInsights = ({ artistId, fetchData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetch = async () => {
    setIsLoading(true);

    try {
      const response = await fetchData(artistId);
      setData(new MarketSignal(response.payload.data));
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!artistId) return;
    fetch();
  }, [artistId]);

  return (
    <Segment basic loading={isLoading} textAlign={"center"}>
      <Header
        size={"huge"}
        content={"Market Signals"}
        subheader={"Averages over the last 36 months"}
        textAlign={"left"}
      />
      {data && (
        <Segment>
          <Statistic.Group widths={"four"}>
            <Statistic label={"Lots Sold"} value={data.numberOfLotsSold()} />
            <Statistic
              label={"Sell-through Rate"}
              value={data.sellThroughRate()}
            />
            <Statistic label={"Amount Sold"} value={data.totalAmountSold()} />
            <Statistic
              label={"Sell Price Over Estimate"}
              value={data.salesOverEstimate()}
            />
          </Statistic.Group>
        </Segment>
      )}
    </Segment>
  );
};

const mapStateToProps = ({ firebase }) => ({
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: (id) => dispatch(getMarketSignals(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArtistMarketInsights);
