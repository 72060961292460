import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Image, Modal, Table } from 'semantic-ui-react';
import { getPiece } from '../../../../redux/actions/piece';
import Piece from '../../../../models/piece';
import { useFirestoreConnect } from 'react-redux-firebase';

const SelectPieceModal = React.memo(({ onPieceSelected }) => {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(state => state.firebase.auth || null);
  const user = useSelector(state => state.firebase.profile || null);
  const pieces = useSelector(state => state.firestore.ordered.pieces);

  const orderBy = ['title', 'asc'];

  useFirestoreConnect({
    orderBy,
    collection: 'pieces',
    doc: user?.current_user_id || auth.uid,
    subcollections: [{ collection: 'pieces' }],
    storeAs: 'pieces',
  });

  const onPieceCellSelected = useCallback(async (pieceID) => {
    setLoading(true);
    try {
      const { payload } = await dispatch(getPiece(pieceID));
      onPieceSelected(new Piece(payload.data));
      setModalOpen(false);
    } catch (e) {
      // Handle error
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [dispatch, onPieceSelected]);

  return (
    <Modal
      trigger={<Button secondary icon="download" content="Populate Form with Artwork Details" labelPosition="left" onClick={() => setModalOpen(true)} />}
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={() => setModalOpen(false)}
      closeIcon
    >
      <Modal.Header content="Select Artwork" />
      <Modal.Content scrolling>
        <Table sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content="Title" />
              <Table.HeaderCell content="Artist" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {pieces?.map(p => {
              const piece = new Piece(p);
              return (
                <Table.Row key={piece._id} onClick={() => onPieceCellSelected(piece._id)}>
                  <Table.Cell>
                    <Image src={piece.images?.[0]?.small_image_url} avatar />
                    {piece.title}
                  </Table.Cell>
                  <Table.Cell>{piece.artist?.name}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button basic content="Cancel" onClick={() => setModalOpen(false)} />
      </Modal.Actions>
    </Modal>
  );
});

export default SelectPieceModal;
