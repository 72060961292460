import React from "react";
import { Divider, Form, Grid, Header } from "semantic-ui-react";
import InsuranceAttachments from "../Attachments/InsuranceAttachments";

const Insurance = ({ piece, onUpdate }) => {
  const { is_insured, insurance_notes, insurance_attachments } = piece;

  const handleChange = (e, { name, value }) => onUpdate({ [name]: value });
  const handleCheck = (e, { name, checked }) => onUpdate({ [name]: checked });

  return (
    <Form>
      <Form.Group>
        <Header size="large" content={"Insurance"} />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Insured</label>
        </Form.Field>
        <Form.Field width={12}>
          <Form.Checkbox
            name={"is_insured"}
            label={"This item is insured"}
            defaultChecked={is_insured === true}
            onChange={handleCheck}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Notes</label>
        </Form.Field>
        <Form.TextArea
          width={12}
          rows={4}
          name={"insurance_notes"}
          placeholder="Insurance Notes"
          defaultValue={insurance_notes}
          onChange={handleChange}
        />
      </Form.Group>
      <InsuranceAttachments
        attachments={insurance_attachments}
        onAttachmentsUpdated={(attachments) =>
          onUpdate({ insurance_attachments: attachments })
        }
      />
    </Form>
  );
};

export default Insurance;
