import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { initScripts } from "./utils";
import App from "./containers/App";
import packageInfo from "../package.json";

import "react-sliding-pane/dist/react-sliding-pane.css";
import "semantic-ui-css/semantic.min.css";
import "./index.scss";

initScripts();

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://fea3c8d40e2249dba92aee2b24236466@o1424509.ingest.sentry.io/4503990413819904",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: `${packageInfo.name}@${packageInfo.version}`,
  });
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
