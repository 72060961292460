import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Header, Modal, Table } from "semantic-ui-react";
import { useFirestoreConnect } from "react-redux-firebase";
import Piece from "../../../../../../models/piece";
import PieceCell from "./PieceCell";
import { updateTagPieces } from "../../../../../../redux/actions/tag";
import { sortPieces } from "../../../../../../utils/sort";

const Index = ({
  auth,
  user,
  configuration,
  tag,
  currentSelectedPieces,
  update,
}) => {
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [orderBy, setOrderBy] = useState(["title", "asc"]);
  const [selectedPieces, setSelectedPieces] = useState(
    new Set(currentSelectedPieces) || new Set(),
  );

  useEffect(() => {
    if (saving) return;
    setSelectedPieces(new Set(currentSelectedPieces));
  }, [currentSelectedPieces]);

  useFirestoreConnect([
    {
      collection: "pieces",
      doc: (user && user.current_user_id) || auth.uid,
      subcollections: [{ collection: "pieces" }],
      storeAs: "allPieces",
    },
  ]);
  const pieces = useSelector((state) => state.firestore.ordered.allPieces);

  const onSelectCell = (pieceID) => {
    if (selectedPieces.has(pieceID)) {
      selectedPieces.delete(pieceID);
    } else {
      selectedPieces.add(pieceID);
    }
    setSelectedPieces(new Set(selectedPieces));
  };

  const handleClose = () => {
    setError(null);
    setModalOpen(false);
  };

  const handleSave = async () => {
    setError(null);
    setSaving(true);
    try {
      await update(tag._id, {
        piece_ids: Array.from(selectedPieces),
      });
      handleClose();
    } catch (e) {
      setError(e);
    }
    setSaving(false);
  };

  return (
    <Modal
      trigger={
        <Button
          basic
          floated={"right"}
          content={"Edit List"}
          onClick={() => setModalOpen(true)}
        />
      }
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={handleClose}
      closeIcon
    >
      <Modal.Header content={`Edit Artworks for ${tag.name}`} />
      <Modal.Content scrolling>
        <Header
          size={"tiny"}
          content={`You have selected ${selectedPieces.size} artworks`}
        />
        <Table sortable striped selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1} />
              <Table.HeaderCell
                key={"title"}
                width={4}
                content={"Title"}
                sorted={
                  orderBy[0] === "title"
                    ? orderBy[1] === "asc"
                      ? "ascending"
                      : "descending"
                    : null
                }
                onClick={() =>
                  setOrderBy(
                    orderBy[1] === "asc" ? ["title", "desc"] : ["title", "asc"],
                  )
                }
              />
              {configuration.artist && (
                <Table.HeaderCell
                  key={"artist"}
                  width={3}
                  active
                  content={"Artist"}
                  sorted={
                    orderBy[0] === "artist.name"
                      ? orderBy[1] === "asc"
                        ? "ascending"
                        : "descending"
                      : null
                  }
                  onClick={() =>
                    setOrderBy(
                      orderBy[1] === "asc"
                        ? ["artist.name", "desc"]
                        : ["artist.name", "asc"],
                    )
                  }
                />
              )}
              {configuration.gallery && (
                <Table.HeaderCell
                  key={"gallery"}
                  width={3}
                  content={"Gallery"}
                  sorted={
                    orderBy[0] === "gallery.name"
                      ? orderBy[1] === "asc"
                        ? "ascending"
                        : "descending"
                      : null
                  }
                  onClick={() =>
                    setOrderBy(
                      orderBy[1] === "asc"
                        ? ["gallery.name", "desc"]
                        : ["gallery.name", "asc"],
                    )
                  }
                />
              )}
              {configuration.purchase_price && (
                <Table.HeaderCell
                  key={"price"}
                  width={2}
                  content={"Price"}
                  sorted={
                    orderBy[0] === "purchase_price"
                      ? orderBy[1] === "asc"
                        ? "ascending"
                        : "descending"
                      : null
                  }
                  onClick={() =>
                    setOrderBy(
                      orderBy[1] === "asc"
                        ? ["purchase_price", "desc"]
                        : ["purchase_price", "asc"],
                    )
                  }
                />
              )}
              {configuration.valuation_price && (
                <Table.HeaderCell
                  key={"valuation"}
                  width={2}
                  content={"Valuation"}
                  sorted={
                    orderBy[0] === "valuation_price"
                      ? orderBy[1] === "asc"
                        ? "ascending"
                        : "descending"
                      : null
                  }
                  onClick={() =>
                    setOrderBy(
                      orderBy[1] === "asc"
                        ? ["valuation_price", "desc"]
                        : ["valuation_price", "asc"],
                    )
                  }
                />
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortPieces(
              (pieces || []).map((piece) => new Piece(piece)),
              orderBy,
            ).map((piece) => (
              <PieceCell
                key={piece._id}
                pieceId={piece._id}
                piece={piece}
                configuration={configuration}
                isSelected={selectedPieces.has(piece._id)}
                onSelectCell={onSelectCell}
              />
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button content={"Cancel"} onClick={handleClose} />
        <Button loading={saving} content={"Save"} onClick={handleSave} />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = ({ firebase }) => ({
  auth: firebase.auth || null,
  user: firebase.profile || null,
  configuration: {
    date:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.date,
    artist:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.artist,
    gallery:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.gallery,
    purchase_price:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.purchase_price,
    valuation_price:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.valuation_price,
    inventory_identifier:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.inventory_identifier,
    status:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.status,
    tags:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.tags,
  },
});

const mapDispatchToProps = (dispatch) => ({
  update: (id, data) => dispatch(updateTagPieces(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
