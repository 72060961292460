import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import DropZone from "../../../components/DropZone";
import ErrorMessage from "../../../components/ErrorMessage";
import { createAttachment } from "../../../redux/actions/attachment";
import PropTypes from "prop-types";
import Attachment from "../../../models/attachment";

const UploadAttachmentModal = ({ create, type, handleAttachmentUploaded }) => {
  const [modalOpen, setModalOpen] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    const data = new FormData();
    data.append("type", type);
    if (name) data.append("name", name);
    if (file) data.append("file", file);

    setError(null);
    setLoading(true);

    try {
      const { payload } = await create(data);
      handleAttachmentUploaded(new Attachment(payload.data));
      // Reset
      setName(null);
      setFile(null);
      setModalOpen(false);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  const onFilesAdded = (files) => {
    setFile(files[0]);
    setName(files[0].name);
  };

  return (
    <Modal
      trigger={
        <div style={{ textAlign: "right" }}>
          <a onClick={() => setModalOpen(true)}>Upload Attachment</a>
        </div>
      }
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={() => setModalOpen(false)}
      closeIcon
    >
      <Modal.Header size={"huge"} content={"Upload Attachment"} />
      <Modal.Content>
        {error && <ErrorMessage error={error} />}
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              <label>File</label>
            </Form.Field>
            <Form.Field width={12}>
              <DropZone
                onDrop={onFilesAdded}
                multiple={false}
                file={file}
                icon={"file outline"}
                placeholder={"Drag file here or click to upload"}
                accept=".xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf"
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={4}>
              <label>Name</label>
            </Form.Field>
            <Form.Field width={12}>
              <Form.Input
                name="name"
                placeholder="Name"
                autoComplete={"off"}
                defaultValue={name}
                onChange={(e, { value }) => setName(value)}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content={"Close"} onClick={() => setModalOpen(false)} />
        <Button
          primary
          disabled={!file || !name}
          content={"Submit"}
          loading={loading}
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};

UploadAttachmentModal.propTypes = {
  type: PropTypes.string,
  handleAttachmentUploaded: PropTypes.func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createAttachment(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadAttachmentModal);
