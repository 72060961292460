import React, { useState } from "react";
import {
  Button,
  Confirm,
  Grid,
  Header,
  Item,
  Segment,
} from "semantic-ui-react";
import { deleteMe } from "../../../../../../redux/actions/me";
import { connect } from "react-redux";
import ErrorMessage from "../../../../../../components/ErrorMessage";

const DeleteAccount = ({ remove }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(null);

  const handleDelete = async () => {
    setError(null);
    setLoading(true);

    try {
      await remove();
      setConfirmDeleteOpen(false);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <>
      <Grid.Row>
        <Grid.Column>
          {error && <ErrorMessage error={error} />}
          <Segment loading={loading}>
            <Header size={"large"} content={"Delete account"} />
            <p>
              Once you delete your user, there is no going back. Please be
              certain.
            </p>
            <Button
              negative
              content={"Delete Account"}
              onClick={() => setConfirmDeleteOpen(true)}
            />
            <Confirm
              open={confirmDeleteOpen}
              onCancel={() => setConfirmDeleteOpen(false)}
              onConfirm={handleDelete}
              content="Are you sure you want to close your account and delete all personal information?"
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  remove: () => dispatch(deleteMe()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);
