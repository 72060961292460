import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Breadcrumb,
  Button,
  Confirm,
  Grid,
  Header,
  Image,
  List,
  Loader,
  Segment,
  Transition,
} from "semantic-ui-react";
import {
  deleteAuctionResult,
  getAuctionResult,
  resetAuctionResultState,
} from "../../../../../../redux/actions/auction_result";
// Images
import placeholder from "../../../../../../images/placeholder.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import AuthLayout from "../../../../../../layouts/AuthLayout";
import {
  ARTIST_PATH,
  AUCTION_PATH,
  DASHBOARD_PATH,
} from "../../../../../../constants/paths";
import EditAuctionRecordSidePane from "../EditAuctionRecordSidePane/EditAuctionRecordSidePane";
import AuctionRecordGroupings from "./AuctionRecordGroupings";

const Cell = ({ header, content, image, link }) => {
  return (
    <Grid.Column style={{ whiteSpace: "pre-line" }}>
      <List>
        <List.Item>
          <List.Content>
            <Header size="small" content={header} />
            {!link && (
              <List.Description>
                {image && <Image src={image || placeholder} avatar />}
                {content || "N/A"}
              </List.Description>
            )}
            {link && (
              <List.Description as={Link} to={{ pathname: link }}>
                {image && <Image src={image || placeholder} avatar />}
                {content || "N/A"}
              </List.Description>
            )}
          </List.Content>
        </List.Item>
      </List>
    </Grid.Column>
  );
};

const AuctionRecordContent = ({ auctionRecord, handleConfirm, confirmOpen, setConfirmOpen, user, currencies }) => {
  if (!auctionRecord) return null;
  const {
    lot_number,
    literature,
    artist,
    medium,
    size,
    catalogue_note,
    provenance,
    exhibited,
    condition_report,
    auction,
  } = auctionRecord;

  return (
    <Grid stackable>
    <Grid.Row columns={2}>
      <Grid.Column>
        <Segment basic>
          <Breadcrumb>
            <Breadcrumb.Section
              as={Link}
              to={DASHBOARD_PATH}
              content={"Home"}
            />
            <Breadcrumb.Divider />
            <Breadcrumb.Section
              as={Link}
              to={AUCTION_PATH}
              content={"Auctions"}
            />
            <Breadcrumb.Divider />
            <Breadcrumb.Section
              as={Link}
              to={`${AUCTION_PATH}/${auction._id}`}
              content={auction.name}
            />
            <Breadcrumb.Divider />
            <Breadcrumb.Section active content={lot_number} />
          </Breadcrumb>
        </Segment>
      </Grid.Column>
      {user && user.roles && user.roles.includes("admin") && (
        <Grid.Column>
          <Segment basic>
            <EditAuctionRecordSidePane auctionRecord={auctionRecord}>
              <Button
                basic
                floated={"right"}
                icon={"edit"}
                content={"Edit Auction Record"}
                labelPosition={"left"}
              />
            </EditAuctionRecordSidePane>
          </Segment>
        </Grid.Column>
      )}
    </Grid.Row>

    <Grid.Row>
      <Grid.Column width={7}>
        <Transition.Group
          style={{ maxHeight: "40em", minHeight: "20em" }}
          as={Image}
          animation={"fade left"}
          duration={600}
          visible={artist}
          centered
          src={(auctionRecord && auctionRecord.image_url) || placeholder}
        />
        <Transition.Group
          basic
          as={Segment}
          animation={"fade up"}
          duration={600}
          visible={auctionRecord}
        >
          <div style={{ textAlign: "center", marginTop: "1em" }}>
            {auctionRecord && auctionRecord.website_url && (
              <Button
                basic
                icon={"globe"}
                labelPosition={"left"}
                content={`View at ${auctionRecord.auction.auction_house.name}`}
                as="a"
                href={auctionRecord.website_url}
                rel="noopener noreferrer"
                target="_blank"
              />
            )}
            {user && user.roles && user.roles.includes("admin") && (
              <>
                <Button
                  negative
                  content={"Delete"}
                  onClick={() => setConfirmOpen(true)}
                />
                <Confirm
                  open={confirmOpen}
                  onCancel={() => setConfirmOpen(false)}
                  onConfirm={handleConfirm}
                  content="Are you sure you want to delete this artist and all of their associated records?"
                />
              </>
            )}
          </div>
        </Transition.Group>
      </Grid.Column>
      <Grid.Column width={9}>
        <Transition.Group
          basic
          as={Segment}
          animation={"fade down"}
          duration={600}
          visible={artist}
        >
          {auctionRecord && (
            <Header
              size="huge"
              content={auctionRecord.title}
              subheader={auctionRecord.date}
            />
          )}
        </Transition.Group>
        <Transition.Group
          basic
          as={Segment}
          animation={"fade up"}
          duration={600}
          visible={artist}
        >
          <Grid stackable>
            <Grid.Row columns={2}>
              <Cell
                header={"Artist"}
                content={artist && artist.name}
                image={artist && artist.small_image_url}
                link={
                  auctionRecord && `${ARTIST_PATH}/${artist && artist._id}`
                }
              />
              <Cell
                header={"Auction"}
                content={auction && auction.name}
                image={auction && auction.small_image_url}
                link={auction && `${AUCTION_PATH}/${auction._id}`}
              />
            </Grid.Row>
            <Grid.Row columns={2}>
              <Cell
                header={"Estimate"}
                content={
                  auctionRecord.estimatePriceString(currencies, user) ||
                  "Price Upon Request"
                }
              />
              <Cell
                header={"Sales Price"}
                content={
                  auctionRecord.hammerPriceString(currencies, user) ||
                  "Price Upon Request"
                }
              />
            </Grid.Row>

            <Grid.Row columns={2}>
              <Cell header={"Size"} content={size} />
            </Grid.Row>

            {medium && (
              <Grid.Row columns={1}>
                <Cell header={"Medium"} content={medium} />
              </Grid.Row>
            )}

            {provenance && (
              <Grid.Row columns={1}>
                <Cell header={"Provenance"} content={provenance} />
              </Grid.Row>
            )}
            {exhibited && (
              <Grid.Row columns={1}>
                <Cell header={"Exhibited"} content={exhibited} />
              </Grid.Row>
            )}
            {literature && (
              <Grid.Row columns={1}>
                <Cell header={"Literature"} content={literature} />
              </Grid.Row>
            )}
            {condition_report && (
              <Grid.Row columns={1}>
                <Cell
                  header={"Condition Report"}
                  content={condition_report}
                />
              </Grid.Row>
            )}
            {catalogue_note && (
              <Grid.Row columns={1}>
                <Cell header={"Catalogue Note"} content={catalogue_note} />
              </Grid.Row>
            )}
          </Grid>
        </Transition.Group>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={16}>
        <AuctionRecordGroupings groupings={auctionRecord.groupings} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
  );
};

const AuctionRecordDetail = () => {
  const { auctionRecordId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loading, auctionRecord, currencies } = useSelector(state => ({
    user: state.firebase.profile,
    loading: state.auctionRecord.isLoadingAuctionRecord,
    auctionRecord: state.auctionRecord.auctionRecord,
    currencies: state.currencies.currencies,
  }));
  const [error, setError] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    dispatch(getAuctionResult(auctionRecordId));
    return () => {
      dispatch(resetAuctionResultState());
    };
  }, [dispatch, auctionRecordId]);

  const handleConfirm = async () => {
    try {
      await dispatch(deleteAuctionResult(auctionRecordId));
      navigate(-1);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <AuthLayout
      title={`${auctionRecord?.title} | ${auctionRecord?.artist?.name}`}
      description={auctionRecord?.medium?.slice(0, 100)}
    >
      {error && <ErrorMessage error={error} />}
      {loading && <Loader active inline={"centered"} />}
      <AuctionRecordContent
        auctionRecord={auctionRecord}
        handleConfirm={handleConfirm}
        confirmOpen={confirmOpen}
        setConfirmOpen={setConfirmOpen}
        user={user}
        currencies={currencies}
      />
    </AuthLayout>
  );
};

export default AuctionRecordDetail
