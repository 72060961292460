import React, { useState } from "react";
import { connect } from "react-redux";
import { Accordion, Button, Grid, Header, Segment } from "semantic-ui-react";
import { updateCurrentUser } from "../../../../../../redux/actions/me";
import ErrorMessage from "../../../../../../components/ErrorMessage";

const Developer = ({ user, update }) => {
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <Grid stackable>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header size={"huge"} content={"API Documentation"} dividing />
          {error && <ErrorMessage error={error} />}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Segment>
            <Header as={"h3"}>Authentication</Header>
            <p>Authentication is required for all API requests. You can obtain an API key by contacting us at <a href="mailto:info@artcollection.io?subject=API Token Request">info@artcollection.io</a>.</p>
            <p>Include the API key in the request header as follows:</p>
            <Segment>
              <pre>
                <code>
                  {`curl -v -H "Authorization: Bearer API_TOKEN"`}
                </code>
              </pre>
              </Segment>
          </Segment>

          <Header as={"h2"}>Endpoints</Header>

          <Accordion fluid styled>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={() => activeIndex === 0 ? setActiveIndex(null) : setActiveIndex(0)}
            >
              <Header as={"h3"}>Search Artists</Header>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Segment>
                <Header as={"h4"}>Request</Header>
                <Segment>
                  <pre>
                    <code>
                      {`curl -v "https://api.arcollection.io/api/v5/public/search_artists?term=ARTIST_NAME`}
                    </code>
                  </pre>
                  </Segment> 
                  <Header as={"h4"}>Response</Header>
                <Segment>
                  <pre>
                    <code>
                      {`[
  {
    "id": "5a7b18bc9c7e0400146c7802",
    "name": "Andy Warhol"
  },
  {
    "id": "5ce31ad3bf16530017ba4336",
    "name": "Andy Denzler"
  }
 ]`}
                    </code>
                  </pre>
              </Segment>
              </Segment>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={() => activeIndex === 1 ? setActiveIndex(null) : setActiveIndex(1)}
            >
              <Header as={"h3"}>Past Auction Records</Header>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <Segment>
                <Header as={"h4"}>Request</Header>
                <Segment>
                  <pre>
                    <code>
                      {`curl -v "https://api.arcollection.io/api/v5/public/artist/:ARTIST_ID/past_auction_records`}
                    </code>
                  </pre>
                  </Segment> 
              </Segment>
              <Segment>
                <Header as={"h4"}>Response</Header>
                <Segment>
                  <pre>
                    <code>
                      {`[{
  "_id": "663171561ba4868e8220a494",
  "title": "John Gould",
  "hammer_currency": "USD",
  "estimate_low": 15000000,
  "estimate_high": 25000000,
  "estimate_currency": "USD",
  "image_url": "https://drqv166o551w1.cloudfront.net/auction_records/663171561ba4868e8220a494/original.jpg",
  "medium_image_url": "https://drqv166o551w1.cloudfront.net/auction_records/663171561ba4868e8220a494/medium.jpg",
  "small_image_url": "https://drqv166o551w1.cloudfront.net/auction_records/663171561ba4868e8220a494/small.jpg",
  "sale_date": "2024-05-16T19:00:00.000Z",
  "hammer_price": 15290000,
  "lot_performance": "within"
}]`}
                    </code>
                  </pre>
                </Segment>
              </Segment>
            </Accordion.Content>
            
          </Accordion>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = ({ me, firebase }) => ({
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateCurrentUser(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Developer);
