import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCurrencies as fetchCurrencies } from '../redux/actions/currencies';

/**
 * Custom hook to handle currency-related operations, fetching them only if they don't already exist.
 */
const useFirebase = () => {
  const dispatch = useDispatch();
  // Assuming the state shape, adjust the selector according to your actual state structure
  const currencies = useSelector(state => state.currencies.currencies);

  /**
   * Fetches currencies if they are not already present in the state.
   */
  useEffect(() => {
    if (!currencies || currencies.length === 0) {
      dispatch(fetchCurrencies());
    }
  }, [currencies, dispatch]); // Depend on currencies to avoid refetching if they already exist

  // The hook doesn't need to return anything unless you have other operations you wish to expose
};

export { useFirebase };