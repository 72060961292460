import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Header, Table } from "semantic-ui-react";
import { getArtistRecordsList } from "../../../../../../redux/actions/artist";
import AuctionRecord from "../../../../../../models/auction_record";
import ArtistAuctionRecordTile from "../ArtistAuctionRecordTile";
import InfiniteScroll from "react-infinite-scroller";

const ArtistAuctionRecords = ({
  auctionRecords,
  artistId,
  user,
  hasMoreItems,
  fetchAuctionRecords,
  currencies,
}) => {
  const items = useMemo(
    () =>
      (auctionRecords || []).map((auctionRecord) => (
        <ArtistAuctionRecordTile
          key={auctionRecord._id}
          auctionRecord={new AuctionRecord(auctionRecord)}
          user={user}
          currencies={currencies}
        />
      )),
    [auctionRecords, user, currencies]
  );

  return (
    <InfiniteScroll
      initialLoad={true}
      loadMore={(page) => fetchAuctionRecords(artistId, page)}
      hasMore={hasMoreItems}
      loader={
        <Header
          key={"loader"}
          textAlign={"center"}
          size={"small"}
          content={"Loading ..."}
        />
      }
    >
      {items.length !== 0 && (
        <Table selectable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={8} content={"Artwork"} key={"Artwork"} />
              <Table.HeaderCell width={3} content={"Auction"} key={"Auction"} />
              <Table.HeaderCell width={3} content={"Estimate"} key={"Estimate"} />
              <Table.HeaderCell width={2} content={"Sales Price"} key={"Sales Price"} />
            </Table.Row>
          </Table.Header>
          <Table.Body>{items}</Table.Body>
        </Table>
      )}
    </InfiniteScroll>
  );
};

const mapStateToProps = ({ firebase, artist, currencies }) => ({
  user: firebase.profile,
  hasMoreItems: artist.hasMoreAuctionRecords,
  auctionRecords: artist.auctionRecords,
  currencies: currencies.currencies,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAuctionRecords: (auction, page) => dispatch(getArtistRecordsList(auction, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistAuctionRecords);
