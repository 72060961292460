const url = "/api/v5/push_tokens";

export const CREATE_PUSH_TOKEN = "CREATE_PUSH_TOKEN";
export const CREATE_PUSH_TOKEN_SUCCESS = "CREATE_PUSH_TOKEN_SUCCESS";
export const CREATE_PUSH_TOKEN_FAILURE = "CREATE_PUSH_TOKEN_FAILURE";

export const createPushToken = (data) => {
  return {
    types: [
      CREATE_PUSH_TOKEN,
      CREATE_PUSH_TOKEN_SUCCESS,
      CREATE_PUSH_TOKEN_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}`,
        method: "POST",
        data,
      },
    },
  };
};
