import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Header, Image, Segment, Table } from "semantic-ui-react";
import { getAuctionResultList } from "../../../../../redux/actions/auction_result";
import placeholder from "../../../../../images/placeholder.png";
import moment from "moment";
import { priceString } from "../../../../../utils/string";
import {
  AUCTION_PATH,
  AUCTION_RECORD_PATH,
} from "../../../../../constants/paths";
import { useNavigate } from "react-router-dom";

const UpcomingLots = ({ fetchAll, user, currencies }) => {
  const navigate = useNavigate();
  const [auctionRecords, setAuctionRecords] = useState(null);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const response = await fetchAll();
    setAuctionRecords(response.payload.data);
  };

  const items = (auctionRecords || []).map(
    ({
      _id,
      title,
      artist,
      small_image_url,
      auction,
      estimate_low,
      estimate_high,
      estimate_currency,
    }) => (
      <Table.Row key={_id} style={{ cursor: "pointer" }}>
        <Table.Cell onClick={() => navigate(`${AUCTION_RECORD_PATH}/${_id}`)}>
          <Image src={small_image_url || placeholder} size="mini" />
        </Table.Cell>

        <Table.Cell onClick={() => navigate(`${AUCTION_RECORD_PATH}/${_id}`)}>
          <Header as="h4">
            <Header.Content content={title} />
            <Header.Subheader content={artist && artist.name} />
          </Header>
        </Table.Cell>
        <Table.Cell
          onClick={
            auction ? () => navigate(`${AUCTION_PATH}/${auction._id}`) : null
          }
        >
          <Header
            size="tiny"
            content={auction && auction.name}
            subheader={
              auction && auction.auction_house && auction.auction_house.name
            }
          />
        </Table.Cell>
        <Table.Cell onClick={() => navigate(`${AUCTION_PATH}/${auction._id}`)}>
          {`${moment(auction.end_date).format("MMM D, YYYY")}`}
        </Table.Cell>
        <Table.Cell onClick={() => navigate(`${AUCTION_RECORD_PATH}/${_id}`)}>
          {estimate_low &&
          estimate_high &&
          estimate_low !== 0 &&
          estimate_high !== 0
            ? `${priceString(
                estimate_low / 100,
                estimate_currency,
                user,
                currencies,
              )} - ${priceString(
                estimate_high / 100,
                estimate_currency,
                user,
                currencies,
              )}`
            : "Estimate Upon Request"}
        </Table.Cell>
      </Table.Row>
    ),
  );

  if (!items || items.length === 0) return null;

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header content={"Upcoming Lots You May Be Interested In"} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Segment basic>
            <Table striped selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1} />
                  <Table.HeaderCell width={8} content={"Name"} />
                  <Table.HeaderCell content={"Auction"} />
                  <Table.HeaderCell content={"Date"} />
                  <Table.HeaderCell content={"Estimate"} />
                </Table.Row>
              </Table.Header>
              <Table.Body>{items}</Table.Body>
            </Table>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = ({ firebase, currencies }) => ({
  user: firebase.profile || null,
  currencies: currencies.currencies,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAll: () =>
    dispatch(getAuctionResultList(1, 5, "my_artists", "upcoming")),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingLots);
