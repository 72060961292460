import React from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  Grid,
  Header,
  Image,
  Loader,
  Menu,
  Segment,
  Table,
} from "semantic-ui-react";
import { connect, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import Invitations from "../Invitations";

const EnterpriseAccount = ({ user }) => {
  useFirestoreConnect([
    {
      collection: "accounts",
      doc: user.current_account_id || user.account,
      storeAs: "account",
    },
  ]);

  const accountData = useSelector((state) => state.firestore.ordered.account);

  if (!isLoaded(accountData)) return <Loader />;
  if (isEmpty(accountData)) return null;

  const account = accountData && accountData[0];
  const numberOfUsers = Object.keys(account.users).length;

  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <Segment>
            <Header size={"large"} content={"Enterprise Plan"} />
            <p>
              You are currently paying for {numberOfUsers} seat
              {numberOfUsers > 1 ? "s" : ""}.
            </p>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      {account && (
        <Invitations accountID={user.current_account_id || user.account} />
      )}
    </>
  );
};

EnterpriseAccount.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = ({ firebase }) => ({
  auth: firebase.auth || null,
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseAccount);
