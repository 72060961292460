import React from "react";
import { connect, useSelector } from "react-redux";
import { Menu } from "semantic-ui-react";
import { deleteTag } from "../../../../redux/actions/tag";
import { toggleActiveTab } from "../../../../redux/actions/piece";
import Tag from "../../../../models/tag";
import { useFirestoreConnect } from "react-redux-firebase";
import AddTagModal from "../../../../components/Modals/Tag/Add/Modal";
import EditTagsModal from "../../../../components/Modals/Tags/Edit";

const TagMenu = ({ auth, user, activeTab, toggleTab }) => {
  useFirestoreConnect([
    {
      orderBy: ["name", "asc"],
      collection: "tags",
      doc: (user && user.current_user_id) || auth.uid,
      subcollections: [{ collection: "tags" }],
      storeAs: "tags",
    },
  ]);
  const tags = useSelector((state) => state.firestore.ordered.tags);

  return (
    <Menu pointing secondary>
      <Menu.Item
        name={"all"}
        active={activeTab === "all"}
        onClick={() => toggleTab("all")
        }
      />
      {(tags || [])
        .map((tag) => new Tag(tag))
        .map((tag) => (
          <Menu.Item
            key={tag._id}
            name={tag.name}
            active={activeTab === tag._id}
            onClick={() => toggleTab(tag._id)}
          />
        ))}
      <AddTagModal />
      <Menu.Menu position={"right"}>
        <EditTagsModal />
      </Menu.Menu>
    </Menu>
  );
};

const mapStateToProps = ({ pieces, firebase }) => ({
  auth: firebase.auth || null,
  user: firebase.profile || null,
  activeTab:
    pieces.activeTab ||
    new Tag({
      _id: "all",
      name: "All",
    }),
});

const mapDispatchToProps = (dispatch) => ({
  delete: (tagID) => dispatch(deleteTag(tagID)),
  toggleTab: (name) => dispatch(toggleActiveTab(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagMenu);
