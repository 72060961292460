import React from "react";
import { Card, Grid, Header } from "semantic-ui-react";
import { connect } from "react-redux";

const Overview = ({ summary }) => {
  if (!summary) return null;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header floated={"left"} content="Collection Overview" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Card.Group stackable centered itemsPerRow={3}>
            <Card
              key="number_of_pieces"
              content={
                <Header
                  style={{ marginTop: "1em", marginBottom: "1em" }}
                  textAlign={"center"}
                  size={"huge"}
                  content={summary.pieces_count}
                  subheader={"Number of Pieces"}
                />
              }
            />
            <Card
              key="cost"
              content={
                <Header
                  style={{ marginTop: "1em", marginBottom: "1em" }}
                  textAlign={"center"}
                  size={"huge"}
                  content={summary.cost_string}
                  subheader={"Amount Spent"}
                />
              }
            />
            <Card
              key="valuation"
              content={
                <Header
                  style={{ marginTop: "1em", marginBottom: "1em" }}
                  textAlign={"center"}
                  size={"huge"}
                  content={summary.valuation_string}
                  subheader={"Valuation"}
                />
              }
            />
          </Card.Group>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = ({ analytics }) => ({
  currency: (analytics.analytics && analytics.analytics.currency) || "USD",
  summary: (analytics.analytics && analytics.analytics.summary) || null,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
