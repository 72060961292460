import React from "react";
import { Checkbox, Header, Image, Segment, Table } from "semantic-ui-react";
import { connect } from "react-redux";
import Piece from "../../../../models/piece";
import PieceCell from "../../../../components/Cells/piece";
import empty_state from "../../../../images/empty_state/piece.png";

const PiecesTable = ({
  pieces,
  configuration,
  orderBy,
  handleOrderByChange,
  selectedPieceIDs = new Set(),
  handSelectAllPieces,
  handleSelectPieceID,
}) => {
  if (!pieces || pieces.length === 0) {
    return (
      <Segment basic textAlign={"center"}>
        <Image size={"small"} src={empty_state} centered />
        <Header
          textAlign={"center"}
          size={"large"}
          content={"No Pieces"}
          subheader={"Please add your first piece to get started"}
        />
      </Segment>
    );
  }

  return (
    <Table sortable striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            key={"check"}
            textAlign={"center"}
            verticalAlign={"middle"}
            style={{ width: "1%" }}
          >
            <Checkbox
              onChange={(_, { checked }) => handSelectAllPieces(checked)}
            />
          </Table.HeaderCell>
          {configuration.inventory_identifier && (
            <Table.HeaderCell
              key={"inventory_identifier"}
              active
              content={"ID"}
              sorted={
                orderBy[0] === "inventory_identifier"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc"
                    ? ["inventory_identifier", "desc"]
                    : ["inventory_identifier", "asc"],
                )
              }
            />
          )}
          <Table.HeaderCell
            key={"title"}
            content={"Title"}
            sorted={
              orderBy[0] === "title"
                ? orderBy[1] === "asc"
                  ? "ascending"
                  : "descending"
                : null
            }
            onClick={() =>
              handleOrderByChange(
                orderBy[1] === "asc" ? ["title", "desc"] : ["title", "asc"],
              )
            }
          />
          {configuration.artist && (
            <Table.HeaderCell
              key={"artist"}
              width={3}
              active
              content={"Artist"}
              sorted={
                orderBy[0] === "artist_name"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc"
                    ? ["artist_name", "desc"]
                    : ["artist_name", "asc"],
                )
              }
            />
          )}
          {configuration.gallery && (
            <Table.HeaderCell
              key={"gallery"}
              width={3}
              content={"Gallery"}
              sorted={
                orderBy[0] === "gallery_name"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc"
                    ? ["gallery_name", "desc"]
                    : ["gallery_name", "asc"],
                )
              }
            />
          )}
          {configuration.location && (
            <Table.HeaderCell
              key={"location"}
              width={3}
              content={"Location"}
              sorted={
                orderBy[0] === "location_name"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc"
                    ? ["location_name", "desc"]
                    : ["location_name", "asc"],
                )
              }
            />
          )}
          {configuration.purchase_price && (
            <Table.HeaderCell
              key={"price"}
              width={2}
              content={"Price"}
              sorted={
                orderBy[0] === "purchase_price"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc"
                    ? ["purchase_price", "desc"]
                    : ["purchase_price", "asc"],
                )
              }
            />
          )}
          {configuration.valuation_price && (
            <Table.HeaderCell
              key={"valuation"}
              width={2}
              content={"Valuation"}
              sorted={
                orderBy[0] === "valuation_price"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc"
                    ? ["valuation_price", "desc"]
                    : ["valuation_price", "asc"],
                )
              }
            />
          )}
          {configuration.category && (
            <Table.HeaderCell
              key={"category"}
              width={2}
              content={"Category"}
              sorted={
                orderBy[0] === "category"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc"
                    ? ["category", "desc"]
                    : ["category", "asc"],
                )
              }
            />
          )}
          {configuration.status && (
            <Table.HeaderCell
              key={"status"}
              width={2}
              content={"Status"}
              sorted={
                orderBy[0] === "status"
                  ? orderBy[1] === "asc"
                    ? "ascending"
                    : "descending"
                  : null
              }
              onClick={() =>
                handleOrderByChange(
                  orderBy[1] === "asc" ? ["status", "desc"] : ["status", "asc"],
                )
              }
            />
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {(pieces || [])
          .map((piece) => new Piece(piece))
          .map((piece) => (
            <PieceCell
              key={piece._id}
              selected={selectedPieceIDs.has(piece._id)}
              pieceId={piece._id}
              piece={piece}
              handleSelectPieceID={handleSelectPieceID}
              configuration={configuration}
            />
          ))}
      </Table.Body>
    </Table>
  );
};

const mapStateToProps = ({ pieces, firebase, auth }) => ({
  user: firebase.profile,
  configuration: {
    date:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.date,
    artist:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.artist,
    gallery:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.gallery,
    location:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.location,
    purchase_price:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.purchase_price,
    valuation_price:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.valuation_price,
    inventory_identifier:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.inventory_identifier,
    category:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.category,
    status:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.status,
    tags:
      firebase.profile &&
      firebase.profile.piece_list_configuration &&
      firebase.profile.piece_list_configuration.tags,
  },
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PiecesTable);
