import React, { useState, useCallback } from "react";
import { Message, Header, Button, Grid } from "semantic-ui-react";
import { useFirebase } from "react-redux-firebase";

const VerifyEmailMessage = () => {
  const firebase = useFirebase();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onButtonPressed = useCallback(async () => {
    if (success) return; // Prevent further action if already successful
    setLoading(true);
    try {
      await firebase.auth().currentUser.sendEmailVerification();
      setSuccess(true);
    } catch (error) {
      console.error("Verification email sending error:", error);
    } finally {
      setLoading(false);
    }
  }, [firebase, success]);

  return success ? (
    <Message success>
      <Message.Header>
        Success! Please check your email inbox to verify your email address.
      </Message.Header>
    </Message>
  ) : (
    <Message info>
      <Grid stackable>
        <Grid.Row style={{ padding: 0 }}>
          <Grid.Column width={14} style={{ padding: 0 }}>
            <Header
              as="h4"
              icon="mail outline"
              content="Verify Your Email Address"
              subheader="To access all features and receive important updates, please verify your email address."
            />
          </Grid.Column>
          <Grid.Column width={2} style={{ padding: 0 }}>
            <Button
              fluid
              color="blue"
              positive={success}
              loading={loading}
              content="Verify"
              onClick={onButtonPressed}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Message>
  );
};

export default VerifyEmailMessage;
