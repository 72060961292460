export const ACCOUNT_PATH = "/account";
export const LOGIN_PATH = "/login";
export const APPLE_LOGIN_PATH = "/apple_login";
export const REDIRECT_PATH = "/redirect";
export const REGISTER_PATH = "/register";
export const PASSWORD_RECOVER_PATH = "/password_recover";
export const PASSWORD_RESET_PATH = "/password_reset";
export const FIREBASE_PATH = "/auth";

export const DASHBOARD_PATH = "/";
export const AUCTION_PATH = "/auction";
export const AUCTION_RECORD_PATH = "/auction_record";
export const AUCTION_HOUSE_PATH = "/auction_house";
export const ARTIST_PATH = "/artist";
export const MUSEUM_PATH = "/museum";
export const GALLERY_PATH = "/gallery";
export const PIECE_PATH = "/piece";
export const LOCATION_PATH = "/location";
export const JOB_PATH = "/job";
export const PRIVATE_ROOM_PATH = "/private_room";
export const CHAT_PATH = "/chat";
export const PRODUCT_PATH = "/product";
export const IMPORT_PATH = "/import";
export const PROFESSIONAL_PATH = "/professional";
export const NOTIFICATIONS_PATH = "/notification";
export const USERS_PATH = "/user";
export const ARTIST_PROPOSALS_PATH = "/artist_proposal";
export const GALLERY_PROPOSALS_PATH = "/gallery_proposal";
export const ARTWORKS_PATH = "/artwork";
export const TAGS_PATH = "/tag";
export const INVITATIONS_PATH = "/invitation";
export const GROUPINGS_PATH = "/grouping";

export const BILLING_REDIRECT_PATH = "/billing_redirect";
export const APPRAISAL_REQUEST_PATH = "/appraisal_request";
export const TWO_FACTOR_SETTING_PATH = "/two_factor_setting";

export const PATHS_BY_ROLE = {
    user: [
      { content: 'Dashboard', to: DASHBOARD_PATH, key: DASHBOARD_PATH },
      { content: 'Artworks', to: PIECE_PATH, key: PIECE_PATH },
      { content: 'Artists', to: ARTIST_PATH, key: ARTIST_PATH },
      { content: 'Galleries', to: GALLERY_PATH, key: GALLERY_PATH },
      { content: 'Locations', to: LOCATION_PATH, key: LOCATION_PATH },
      { content: 'Auctions', to: AUCTION_PATH, key: AUCTION_PATH },
    ],
    admin: [
      { content: 'Dashboard', to: DASHBOARD_PATH, key: DASHBOARD_PATH },
      { content: 'Artworks', to: PIECE_PATH, key: PIECE_PATH },
      { content: 'Artists', to: ARTIST_PATH, key: ARTIST_PATH },
      { content: 'Galleries', to: GALLERY_PATH, key: GALLERY_PATH },
      { content: 'Locations', to: LOCATION_PATH, key: LOCATION_PATH },
      { content: 'Auctions', to: AUCTION_PATH, key: AUCTION_PATH },
    ],
    professional: [
      { content: 'Jobs', to: JOB_PATH, key: JOB_PATH },
      { content: 'Artists', to: ARTIST_PATH, key: ARTIST_PATH },
      { content: 'Galleries', to: GALLERY_PATH, key: GALLERY_PATH },
      { content: 'Auctions', to: AUCTION_PATH, key: AUCTION_PATH },
    ],
    intern: [
      { content: 'Artists', to: ARTIST_PATH, key: ARTIST_PATH },
    ],
  };
