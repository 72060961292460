import { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { createPushToken } from '../redux/actions/push_token'; // Adjust the import path as necessary

const usePushNotifications = (user) => {
  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    setIsSupported(!!(firebase.messaging.isSupported() && user));
  }, [user]);

  const register = async () => {
    if (!isSupported) return;

    try {
      await Notification.requestPermission();
      const messaging = firebase.messaging();

      if (Notification.permission === 'granted') {
        const registration = await navigator.serviceWorker.ready;
        const existingSubscription = await registration.pushManager.getSubscription();

        if (!existingSubscription) {
          const newSubscription = await messaging.getToken({ vapidKey: process.env.REACT_APP_VAPID_KEY });
          if (newSubscription) {
            // Use your action to send the token to your server to register it for push notifications
            createPushToken(newSubscription);
          }
        }
      }
    } catch (error) {
      console.error('Error registering for push notifications: ', error);
    }
  };

  return { isSupported, register };
};

export { usePushNotifications };
