import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Header, Segment, Statistic, List } from "semantic-ui-react";
import { getGroupingMarketSignals } from "../../../redux/actions/grouping";
import MarketSignal from "../../../models/market_signal";
import { useParams } from "react-router-dom";
import { priceString } from "../../../utils/string";

const MarketSignals = ({ fetchData, user, currencies }) => {
  const { groupingId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetch = async () => {
    setIsLoading(true);

    try {
      const { payload } = await fetchData(groupingId);
      setData(payload.data);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!groupingId) return;
    fetch();
  }, [groupingId]);

  return (
    <>
      <Segment attached={"top"}>
        <Header content={"Market Signals"} />
      </Segment>
      <Segment loading={isLoading} textAlign={"left"} attached>
        <List>
          <List.Item
            header={"Average Price"}
            description={priceString(
              data?.average_price / 100,
              "USD",
              user,
              currencies,
            )}
          />
          <List.Item
            header={"Amount Spent"}
            description={priceString(
              data?.amount_spent / 100,
              "USD",
              user,
              currencies,
            )}
          />
          <List.Item
            header={"Lots Sold"}
            description={data?.number_of_lots_sold ?? 0}
          />
        </List>
      </Segment>
    </>
  );
};

const mapStateToProps = ({ firebase, currencies }) => ({
  user: firebase.profile || null,
  currencies: currencies.currencies,
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: (id) => dispatch(getGroupingMarketSignals(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketSignals);
