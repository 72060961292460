import React from "react";
import { useSelector } from "react-redux";
import VerifyEmailMessage from "../../components/Messages/VerifyEmailMessage";
import UpgradeMessage from "../../components/Messages/UpgradeMessage";

const Banners = ({}) => {
  const user = useSelector((state) => state.firebase.profile);
  const emailVerified = useSelector(
    (state) => state.firebase.auth.emailVerified,
  );

  if (!user) return null;
  if (user && !emailVerified) return <VerifyEmailMessage />;
  if (user && user.account_level === "basic") return <UpgradeMessage />;
  return null;
};

export default Banners;
