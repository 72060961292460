import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, Header, Image, List } from "semantic-ui-react";
import placeholder from "../../../../../../../../images/placeholder.png";
import { Link } from "react-router-dom";
import { LOCATION_PATH } from "../../../../../../../../constants/paths";

const Cell = ({ header, content, image, link }) => {
  return (
    <Grid.Column>
      <List>
        <List.Item>
          <List.Content>
            <Header size="small" content={header} />
            {!link && (
              <List.Description>
                {image && <Image src={image || placeholder} avatar />}
                {content || "N/A"}
              </List.Description>
            )}
            {link && (
              <List.Description as={Link} to={{ pathname: link }}>
                {image && <Image src={image || placeholder} avatar />}
                {content || "N/A"}
              </List.Description>
            )}
          </List.Content>
        </List.Item>
      </List>
    </Grid.Column>
  );
};

const Location = ({ piece }) => {
  const { location, location_detail, location_history, location_notes } = piece;

  if (!location) {
    return null;
  }

  return (
    <Grid.Row columns={1}>
      <Grid.Column>
        <Header content={"Location"} dividing />
        <Cell
          header={"Current Location"}
          content={location && location.name}
          image={location && location.image_url}
          link={location && `${LOCATION_PATH}/${location._id}`}
        />
        {location_detail && (
          <Cell header={"Location Details"} content={location_detail} />
        )}
        {location_history && (
          <Cell header={"Location History"} content={location_history} />
        )}
        {location_notes && (
          <Cell header={"Location Notes"} content={location_notes} />
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

Location.propTypes = {
  piece: PropTypes.object,
};

export default Location;
