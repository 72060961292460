import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Image, Table, Loader, Segment, Header } from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import { getGroupingAuctionRecords } from "../../../redux/actions/grouping";
import ErrorMessage from "../../../components/ErrorMessage";
import placeholder from "../../../images/placeholder.png";
import AuctionRecord from "../../../models/auction_record";
import { AUCTION_RECORD_PATH } from "../../../constants/paths";

const AuctionRecords = ({ fetch, error, loading, user, currencies }) => {
  const navigate = useNavigate();
  const { groupingId } = useParams();
  const [auctionRecords, setAuctionRecords] = useState([]);

  useEffect(() => {
    fetchData();
  }, [groupingId]);

  const fetchData = async () => {
    try {
      const { payload } = await fetch(groupingId);
      setAuctionRecords(payload.data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Segment>
      {loading && <Loader active inline={"centered"} />}
      {error && <ErrorMessage error={error} />}
      {auctionRecords.length !== 0 && (
        <Table selectable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2} key={"image"} />
              <Table.HeaderCell width={3} content={"Artwork"} key={"Artwork"} />
              <Table.HeaderCell width={5} content={"Auction"} key={"Auction"} />
              <Table.HeaderCell
                width={3}
                content={"Estimate"}
                key={"Estimate"}
              />
              <Table.HeaderCell
                width={3}
                content={"Sales Price"}
                key={"Sales Price"}
              />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {auctionRecords
              .map((auctionRecord) => new AuctionRecord(auctionRecord))
              .map((auctionRecord, index) => (
                <Table.Row
                  key={index}
                  onClick={() =>
                    navigate(`${AUCTION_RECORD_PATH}/${auctionRecord._id}`)
                  }
                >
                  <Table.Cell>
                    <Image
                      size="tiny"
                      src={auctionRecord.small_image_url || placeholder}
                    />
                  </Table.Cell>
                  <Table.Cell content={auctionRecord.title} />
                  <Table.Cell>
                    <Header
                      size="tiny"
                      content={auctionRecord.auction.name}
                      subheader={auctionRecord.auction.dateString()}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {auctionRecord.estimatePriceString(currencies, user)}
                  </Table.Cell>
                  <Table.Cell>
                    <Header size="tiny">
                      <Header.Content
                        content={auctionRecord.hammerPriceString(
                          currencies,
                          user,
                        )}
                      />
                      {auctionRecord.pricePercentageChange() > 0 && (
                        <Header.Subheader
                          style={{ color: "green" }}
                          content={auctionRecord.pricePercentageChangeString()}
                        />
                      )}
                      {auctionRecord.pricePercentageChange() < 0 && (
                        <Header.Subheader
                          style={{ color: "red" }}
                          content={auctionRecord.pricePercentageChangeString()}
                        />
                      )}
                    </Header>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      )}
    </Segment>
  );
};

const mapStateToProps = ({ firebase, currencies }) => ({
  user: firebase.profile || null,
  currencies: currencies.currencies,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(getGroupingAuctionRecords(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionRecords);
