import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Grid,
  Header,
  Icon,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getProductList } from "../../../../redux/actions/product";
import { PRODUCT_PATH } from "../../../../constants/paths";
import {
  premiumBenefits,
  proBenefits,
  businessBenefits,
} from "../../../../common";
import AuthLayout from "../../../../layouts/AuthLayout";

const ProductsList = ({ error, products, fetchAll }) => {
  useEffect(() => {
    if (!products && !error) {
      fetchAll();
    }
  }, [products, error, fetchAll]);

  const renderBenefits = (benefits) => {
    return benefits.map((benefit, index) => (
      <Popup
        key={index}
        header={benefit.header}
        content={benefit.content}
        trigger={
          <Table.Row disabled={!benefit.enabled}>
            <Table.Cell textAlign="left">{benefit.header}</Table.Cell>
            <Table.Cell textAlign="left" width={1}>
              <Icon name={benefit.icon} />
            </Table.Cell>
          </Table.Row>
        }
      />
    ));
  };

  const renderPlanSegment = (title, content, benefits, buttonText, link) => (
    <Grid.Column>
      <Segment attached="top" style={{ backgroundColor: "black" }}>
        <Header inverted textAlign="center" content={title} />
      </Segment>
      <Segment attached>
        <Header textAlign="center" style={{ padding: "1em" }} content={content} />
        <Table striped textAlign="center">
          <Table.Body>{renderBenefits(benefits)}</Table.Body>
        </Table>
      </Segment>
      <Segment attached="bottom">
        <Button
          fluid
          size="huge"
          content={buttonText}
          color="black"
          as={link.startsWith("mailto") ? "a" : Link}
          to={link.startsWith("mailto") ? undefined : link}
          href={link.startsWith("mailto") ? link : undefined}
        />
      </Segment>
    </Grid.Column>
  );

  return (
    <AuthLayout title={"Pricing & Plans"}>
    <Grid stackable textAlign="center" centered>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header
            textAlign="center"
            size="huge"
            content="Pricing & Plans"
            subheader="Please choose a plan below"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={4}>
        {renderPlanSegment(
          "Premium",
          "Document 50 artworks, unlimited locations, and 1 private room.",
          premiumBenefits,
          "$10 a month",
          `${PRODUCT_PATH}/prod_GtoIm9e69scbOt`
        )}
        {renderPlanSegment(
          "Pro",
          "Document unlimited artworks, locations, and private rooms.",
          proBenefits,
          "$25 a month",
          `${PRODUCT_PATH}/prod_GmPwWEPGSWMIJ8`
        )}
        {renderPlanSegment(
          "Business",
          "Document unlimited artworks, locations, and private rooms.",
          businessBenefits,
          "Contact Us",
          "mailto:info@artcollection.io?subject=Business Account Inquiry"
        )}
      </Grid.Row>
    </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ products }) => ({
  products: products.products,
  loading: products.isLoadingProductList,
  error: products.error,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAll: () => dispatch(getProductList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsList);