import React from "react";
import { Table } from "semantic-ui-react";
import empty_state from "../../../../../../images/empty_state/piece.png";
import EmptyState from "../../../../../../components/EmptyState";
import PrivateRoomPieceRecordTile from "../PrivateRoomPieceRecordTile";

const PrivateRoomPieceRecords = ({ privateRoom }) => {
  const pieces = privateRoom.pieces || [];

  if (pieces.length === 0) {
    return (
      <EmptyState
        image={empty_state}
        title={"No Artwork"}
        detail={
          "At the moment, you don't own any artworks for this Private Room."
        }
      />
    );
  }

  return (
    <Table sortable striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={6} content={"Title"} />
          {privateRoom.fields.artist && <Table.HeaderCell content={"Artist"} />}
          {privateRoom.fields.gallery && (
            <Table.HeaderCell content={"Gallery"} />
          )}
          {privateRoom.fields.purchase_price && (
            <Table.HeaderCell content={"Purchase Price"} />
          )}
          {privateRoom.fields.valuation_price && (
            <Table.HeaderCell content={"Valuation Price"} />
          )}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {(pieces || []).map((piece) => (
          <PrivateRoomPieceRecordTile
            key={piece._id}
            piece={piece}
            fields={privateRoom.fields}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default PrivateRoomPieceRecords;
