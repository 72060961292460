import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, Header, Image, List } from "semantic-ui-react";
import placeholder from "../../../../../../../../images/placeholder.png";
import { Link } from "react-router-dom";
import Attachment from "../../../../../../../../components/Attachment";
import { GALLERY_PATH } from "../../../../../../../../constants/paths";

const Cell = ({ header, content, image, link }) => {
  return (
    <Grid.Column>
      <List>
        <List.Item>
          <List.Content>
            <Header size="small" content={header} />
            {!link && (
              <List.Description>
                {image && <Image src={image || placeholder} avatar />}
                {content || "N/A"}
              </List.Description>
            )}
            {link && (
              <List.Description as={Link} to={{ pathname: link }}>
                {image && <Image src={image || placeholder} avatar />}
                {content || "N/A"}
              </List.Description>
            )}
          </List.Content>
        </List.Item>
      </List>
    </Grid.Column>
  );
};

const Acquisition = ({ piece }) => {
  const {
    gallery,
    purchase_date,
    purchase_price,
    purchase_attachments,
    purchaseDateString,
  } = piece;

  if (
    !gallery &&
    !purchase_date &&
    !purchase_price &&
    (!purchase_attachments || purchase_attachments.length === 0)
  ) {
    return null;
  }

  return (
    <>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header content={"Acquisition"} dividing />
        </Grid.Column>
        {gallery && (
          <Cell
            header={"Gallery"}
            content={gallery && gallery.name}
            image={gallery && gallery.image_url}
            link={gallery && `${GALLERY_PATH}/${gallery._id}`}
          />
        )}
        {purchase_date && (
          <Cell header={"Purchase Date"} content={purchaseDateString} />
        )}
        {purchase_price && (
          <Cell
            header={"Purchase Price"}
            content={piece.purchasePriceString()}
          />
        )}
      </Grid.Row>
      {purchase_attachments && purchase_attachments.length > 0 && (
        <Grid.Row columns={1}>
          <Grid.Column>
            {(purchase_attachments || []).map((attachment) => (
              <Attachment key={attachment._id} attachment={attachment} />
            ))}
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  );
};

Acquisition.propTypes = {
  piece: PropTypes.object,
};

export default Acquisition;
