import { configureStore } from "@reduxjs/toolkit";
import axios from "axios";
import axiosMiddleware from "redux-axios-middleware";
import * as Sentry from "@sentry/react";
import reducer from "./reducer";
import { auth, appCheck } from "../utils/firebase";

// Axios client configuration
const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Middleware configuration
const middlewareConfig = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      {
        success: async ({ getState, dispatch, getSourceAction }, req) => {
          try {
            const role = localStorage.getItem("role") ?? "user";
            const userId = localStorage.getItem("user_id");
            const currentUser = auth.currentUser;

            const headers = {
              ...req.headers,
              Role: role,
              ...(userId && { user_id: userId }),
              ...(currentUser && { Authorization: `JWT ${await currentUser.getIdToken()}` })
            };

            req.headers = headers;
            return req;
          } catch (error) {
            return Promise.reject(error);
          }
        },
        error: (context, error) => Promise.reject(error),
      },
    ],
    response: [
      {
        success: (context, res) => Promise.resolve(res),
        error: (context, error) => Promise.reject(error),
      },
    ],
  },
};

// Sentry Redux enhancer configuration
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

// Redux store configuration
export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(axiosMiddleware(client, middlewareConfig)),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
});

export default store;