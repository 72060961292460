import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid, Header, Segment, Table } from "semantic-ui-react";
import { deleteAppraisal } from "../../../../../redux/actions/appraisal";
import AppraisalCell from "./AppraisalCell";
import AddAppraisalForm from "./AddAppraisalModal";
import AppraisalRequestModal from "../../../../../components/Modals/AppraisalRequestModal";

const Appraisals = ({ auth, piece, onUpdate }) => {
  const [appraisals, setAppraisals] = useState(piece.appraisals || []);

  const onAppraisalCreated = (appraisal) => {
    setAppraisals([appraisal, ...appraisals]);
    onUpdate({ appraisals: [...appraisals, appraisal] });
  };

  const onAppraisalDeleted = (appraisal) => {
    setAppraisals(appraisals.filter((a) => a._id !== appraisal._id));
    onUpdate({ appraisals: appraisals.filter((a) => a._id !== appraisal._id) });
  };

  return (
    <Segment basic>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header floated={"left"} size="large" content="Appraisals" />
            <AddAppraisalForm
              icon={"plus"}
              floated={"right"}
              onAppraisalCreated={onAppraisalCreated}
            />
          </Grid.Column>
        </Grid.Row>
        {appraisals.length >= 1 && (
          <Grid.Row>
            <Grid.Column width={16}>
              <Table>
                <Table.Header fullWidth>
                  <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Value</Table.HeaderCell>
                    <Table.HeaderCell width={2} />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {appraisals.map((appraisal) => (
                    <AppraisalCell
                      key={appraisal._id}
                      appraisal={appraisal}
                      pieceId={piece._id}
                      onAppraisalDeleted={onAppraisalDeleted}
                    />
                  ))}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        )}
        {appraisals.length === 0 && (
          <Grid.Row>
            <Grid.Column
              width={16}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Header
                size={"huge"}
                content={"No Appraisals"}
                subheader={
                  "At the moment, you don't have any appraisals for this artwork."
                }
                textAlign={"center"}
              />
              <div>
                <AddAppraisalForm onAppraisalCreated={onAppraisalCreated} />
                <AppraisalRequestModal />
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Segment>
  );
};

const mapStateToProps = ({ firebase }) => ({
  auth: firebase.auth || null,
});

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(deleteAppraisal(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Appraisals);
