import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { Button, Form, Grid, Header, Image, Modal } from "semantic-ui-react";
import download from "downloadjs";

import { createAppraisalRequest } from "../../../redux/actions/appraisal_request";
import ErrorMessage from "../../ErrorMessage";
import { appraisalOptions } from "../../../common";
import DropZone from "../../DropZone";

const AppraisalRequestModal = ({ user, create }) => {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [section, setSection] = useState("pieces");

  const [files, setFiles] = useState([]);
  const [reason, setReason] = useState("valuation");
  const [email, setEmail] = useState(user.email || null);
  const [phoneNumber, setPhoneNumber] = useState(user.phone_number || null);
  const [name, setName] = useState(user.name || null);
  const [pieceID, setPieceID] = useState(null);
  const descriptionRef = useRef(null);

  const onAddImages = (images) => {
    setFiles([...files, ...images]);
  };

  const onRemoveImage = (imageID) => {
    setFiles(files.filter((a) => a.preview !== imageID));
  };

  const onClear = () => {
    setFiles([]);
    descriptionRef.current.value = "";
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      var formData = new FormData();
      formData.append("reason", reason);
      formData.append("description", descriptionRef.current.value);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone_number", phoneNumber);
      formData.append("piece_id", pieceID);

      for (const file of files) {
        formData.append("images", file, file.name);
      }

      await create(formData);
      onClear();
      window.scrollTo(0, 0);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  const handleClose = () => {
    setLoading(false);
    setModalOpen(false);
    setSection("pieces");
  };

  return (
    <Modal
      trigger={
        <Button
          content={"Request Appraisal"}
          onClick={() => setModalOpen(true)}
        />
      }
      open={modalOpen}
      closeOnDimmerClick={false}
      onClose={handleClose}
      closeIcon
    >
      <Modal.Header content={"Create Appraisal Request"} />
      <Modal.Content scrolling>
        {error && <ErrorMessage error={error} />}
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form>
                <Form.Field>
                  <Header
                    size={"medium"}
                    content={"Pictures"}
                    subheader={"Upload high quality images of the object"}
                  />
                </Form.Field>

                <Form.Field>
                  <DropZone
                    style={{ height: "140px" }}
                    onDrop={(acceptedFiles) => onAddImages(acceptedFiles)}
                    multiple={true}
                    icon={"file"}
                    placeholder={"Drag document here or click to upload"}
                    accept={"image/*"}
                  />
                </Form.Field>

                <Form.Field>
                  {(files || []).map(({ preview }) => (
                    <Image
                      key={preview}
                      size={"small"}
                      label={{
                        name: preview,
                        type: "new",
                        color: "red",
                        corner: "right",
                        onRemove: () => onRemoveImage(preview),
                      }}
                      src={preview}
                    />
                  ))}
                </Form.Field>

                <Form.Field>
                  <Header
                    size={"medium"}
                    content={"Reason"}
                    subheader={"What type of appraisal are you looking for?"}
                  />
                </Form.Field>

                <Form.Select
                  placeholder={"Reason"}
                  autoComplete={"off"}
                  options={appraisalOptions}
                  defaultValue={"valuation"}
                  onChange={(e, { value }) => setReason(value)}
                  search
                />

                <Form.Field>
                  <Header
                    size={"medium"}
                    content={"Description"}
                    subheader={
                      "Provide a detailed description of the object (i.e. condition, artist or brand, material, provenance)."
                    }
                  />
                </Form.Field>

                <Form.Field>
                  <textarea placeholder={"Description"} ref={descriptionRef} />
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column>
              <Form>
                <Form.Field>
                  <Header
                    size={"huge"}
                    content={"Your Contact Information"}
                    subheader={"Where the appraiser can contact you"}
                  />
                </Form.Field>

                <Form.Input
                  label={"Name"}
                  placeholder={"Name"}
                  defaultValue={name}
                  onChange={(e, { value }) => setName(value)}
                />

                <Form.Input
                  label={"Email"}
                  placeholder={"Email"}
                  defaultValue={email}
                  onChange={(e, { value }) => setEmail(value)}
                />

                <Form.Input
                  label={"Phone"}
                  placeholder={"Phone Number"}
                  defaultValue={phoneNumber}
                  onChange={(e, { value }) => setPhoneNumber(value)}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button basic content="Cancel" onClick={handleClose} />
        <Button
          primary
          loading={loading}
          content={"Submit"}
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = ({ firebase }) => ({
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createAppraisalRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppraisalRequestModal);
