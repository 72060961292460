import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Header, Message, Grid } from "semantic-ui-react";
import { PRODUCT_PATH } from "../../constants/paths";

const UpgradeMessage = () => {
  const navigate = useNavigate();
  return (
    <Message color={"black"}>
      <Grid stackable>
        <Grid.Row style={{ padding: 0 }}>
          <Grid.Column width={14} style={{ padding: 0 }}>
            <Header
              size={"small"}
              inverted
              icon={"lock"}
              content={"Upgrade to our Premium accounts."}
              subheader={
                "Please upgrade to one of our premium accounts to unlock full features and functionality."
              }
            />
          </Grid.Column>
          <Grid.Column width={2} style={{ padding: 0 }}>
            <Button
              fluid
              primary
              floated={"right"}
              content={"Upgrade"}
              onClick={() => navigate(PRODUCT_PATH)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Message>
  );
};

export default UpgradeMessage;
