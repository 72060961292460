import React from "react";
import { Form } from "semantic-ui-react";
import { currencyOptions } from "../../../../common";
import SelectGalleryModal from "../../Gallery/Select";
import FormDatePicker from "../../../Forms/FormDatePicker";

const PurchaseInfo = ({ onUpdate }) => {
  return (
    <Form>
      <Form.Group>
        <Form.Field width={4}>
          <label>Purchase Location</label>
        </Form.Field>
        <Form.Field width={12}>
          <SelectGalleryModal
            onSelectGallery={(value) => onUpdate({ gallery: value })}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Purchase Date</label>
        </Form.Field>
        <Form.Field width={12}>
          <FormDatePicker
            fluid
            name="purchase_date"
            placeholder="Purchase Date"
            autoComplete={"off"}
            onChange={(e, { name, value }) => onUpdate({ [name]: value })}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Purchase Price</label>
        </Form.Field>
        <Form.Select
          fluid
          name="currency"
          placeholder="$"
          options={currencyOptions}
          autoComplete={"off"}
          onChange={(e, { name, value }) => onUpdate({ [name]: value })}
          search
          width={2}
        />
        <Form.Input
          fluid
          name="purchase_price"
          placeholder="Purchase Price"
          type="number"
          step="0.01"
          autoComplete={"off"}
          onChange={(e, { name, value }) => onUpdate({ [name]: value })}
          width={10}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Valuation Price</label>
        </Form.Field>
        <Form.Select
          fluid
          name="valuation_currency"
          placeholder="$"
          options={currencyOptions}
          autoComplete={"off"}
          onChange={(e, { name, value }) => onUpdate({ [name]: value })}
          search
          width={2}
        />
        <Form.Input
          fluid
          name="valuation_price"
          placeholder="Valuation Price"
          type="number"
          step="0.01"
          autoComplete={"off"}
          onChange={(e, { name, value }) => onUpdate({ [name]: value })}
          width={10}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Purchase Notes</label>
        </Form.Field>
        <Form.TextArea
          width={12}
          rows={4}
          name="purchase_notes"
          placeholder="Purchase Notes"
          onChange={(e, { name, value }) => onUpdate({ [name]: value })}
          autoComplete={"off"}
        />
      </Form.Group>
    </Form>
  );
};

export default PurchaseInfo;
