import React, { useState } from "react";
import { Button, Form, Grid, Header, Modal } from "semantic-ui-react";
import SlidingPane from "react-sliding-pane";
import { connect } from "react-redux";
import {
  createAuction,
  updateAuction,
} from "../../../../../../redux/actions/auction";
import DropZone from "../../../../../../components/DropZone";
import FormDatePicker from "../../../../../../components/Forms/FormDatePicker";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import { auctionStateOptions } from "../../../../../../common";

const EditAuctionSidePain = ({ auction, children, update, create, user }) => {
  const [loading, setLoading] = useState(false);
  const [sidePainOpen, setSidePainOpen] = useState(false);
  const [error, setError] = useState(null);

  const [id] = useState(auction._id);
  const [imageUrl] = useState(auction.image_url || null);
  const [file, setFile] = useState(null);
  const [name, setName] = useState(auction.name || "");
  const [state, setState] = useState(auction.state || "");
  const [websiteUrl, setWebsiteUrl] = useState(auction.website_url || "");
  const [startDate, setBirthDate] = useState(auction.start_date);
  const [endDate, setDeathDate] = useState(auction.end_date);
  const [about, setAbout] = useState(auction.about || "");

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    const data = {
      name,
      file,
      about,
      website_url: websiteUrl,
      start_date: startDate,
      end_date: endDate,
      state,
    };

    var formData = new FormData();

    for (var key in data) {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    }

    try {
      if (id) {
        await update(id, formData);
      } else {
        await create(formData);
      }

      setSidePainOpen(false);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <>
      <SlidingPane
        className="some-custom-class"
        overlayClassName="overlay"
        isOpen={sidePainOpen}
        title={
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header
                  floated={"left"}
                  content={id ? "Edit Auction" : "Create Auction"}
                />

                <Button
                  primary
                  content={"Save"}
                  floated={"right"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
        onRequestClose={() => setSidePainOpen(false)}
      >
        <Form fluid>
          {error && <ErrorMessage error={error} />}
          <Form.Group>
            <Form.Field width={4}>
              <label>Image</label>
            </Form.Field>
            <Form.Field width={12}>
              <DropZone
                onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
                multiple={false}
                accept="image/*"
                icon={"image"}
                image={(file && file.preview) || imageUrl}
                placeholder={"Drag auction image here or click to upload"}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Name</label>
            </Form.Field>
            <Form.Input
              fluid
              defaultValue={name}
              width={12}
              placeholder="Name"
              autoComplete={"off"}
              onChange={(e, { value }) => setName(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Website</label>
            </Form.Field>
            <Form.Input
              fluid
              name="website_url"
              defaultValue={websiteUrl}
              width={12}
              placeholder="Website"
              autoComplete={"off"}
              onChange={(e, { value }) => setWebsiteUrl(value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Start & End Date</label>
            </Form.Field>
            <FormDatePicker
              width={6}
              placeholder="Start Date"
              value={startDate}
              autoComplete={"off"}
              handleDayChange={(day) => setBirthDate(day)}
            />
            <FormDatePicker
              width={6}
              placeholder="End Date"
              value={endDate}
              autoComplete={"off"}
              handleDayChange={(day) => setDeathDate(day)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>Status</label>
            </Form.Field>
            <Form.Select
              width={12}
              defaultValue={state}
              placeholder={"State"}
              autoComplete={"off"}
              options={auctionStateOptions}
              onChange={(e, { value }) => setState(value)}
              search
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={4}>
              <label>About</label>
            </Form.Field>
            <Form.TextArea
              defaultValue={about}
              width={12}
              placeholder="About"
              rows={8}
              autoComplete={"off"}
              onChange={(e, { value }) => setAbout(value)}
            />
          </Form.Group>
        </Form>
      </SlidingPane>

      <div onClick={() => setSidePainOpen(true)}>{children}</div>
    </>
  );
};

const mapStateToProps = ({ firebase }) => ({
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createAuction(data)),
  update: (id, data) => dispatch(updateAuction(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAuctionSidePain);
