import React, { useState } from "react";
import { Button, Confirm, Table } from "semantic-ui-react";
import moment from "moment";
import EditModal from "../../../../../components/Modals/Appraisal/EditAppraisalModal";
import PropTypes from "prop-types";
import { deleteAppraisal } from "../../../../../redux/actions/appraisal";
import { connect } from "react-redux";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Appraisal from "../../../../../models/appraisal";

const AppraisalCell = ({ pieceId, appraisal, remove, onAppraisalDeleted }) => {
  const [data, setData] = useState(appraisal);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const onAppraisalRemoved = async (appraisal) => {
    setIsDeleting(true);
    try {
      await remove(appraisal._id);
      onAppraisalDeleted(appraisal);
    } catch (e) {
      console.error("e", e);
    }
    setIsDeleting(false);
  };

  const onDownloadButtonPressed = async () => {
    if (appraisal.url) {
      window.open(appraisal.url, "_blank");
    } else if (appraisal.file_reference) {
      setIsDownloading(true);
      try {
        const storage = getStorage();
        const url = await getDownloadURL(
          ref(storage, appraisal.file_reference),
        );
        window.open(url, "_blank");
      } catch (e) {
        console.error(e);
      }
      setIsDownloading(false);
    }
  };

  return (
    <Table.Row>
      <Table.Cell content={moment(data.date).format("MMM Do YYYY")} />
      <Table.Cell>{data.valueString()}</Table.Cell>
      <Table.Cell>
        <Button.Group floated={"right"}>
          <EditModal 
            appraisal={appraisal} 
            pieceId={pieceId}
            onAppraisalUpdated={(updatedAppraisal) => setData(new Appraisal(updatedAppraisal))}
          />
          <Button
            basic
            icon="download"
            loading={isDownloading}
            onClick={() => onDownloadButtonPressed()}
          />
          <Button
            basic
            icon="close"
            floated="right"
            loading={isDeleting}
            onClick={() => setConfirmDelete(true)}
          />
          <Confirm
            content={`Are you sure you want to delete this appraisal?`}
            open={confirmDelete}
            onCancel={() => setConfirmDelete(false)}
            onConfirm={() => onAppraisalRemoved(appraisal)}
          />
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );
};

AppraisalCell.propTypes = {
  appraisal: PropTypes.object,
  remove: PropTypes.func,
  onAppraisalDeleted: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(deleteAppraisal(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppraisalCell);
