import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Breadcrumb,
  Button,
  Confirm,
  Grid,
  Header,
  Transition,
} from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  deletePrivateRoom,
  getPrivateRoom,
} from "../../../../../../redux/actions/private_room";
import { Mixpanel } from "../../../../../../utils/Mixpanel";
import EditPrivateRoomSidePane from "../EditPrivateRoomSidePane";
import PrivateRoomPieceRecords from "../PrivateRoomPieceRecords";
import AuthLayout from "../../../../../../layouts/AuthLayout";
import { useFirestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import PrivateRoom from "../../../../../../models/private_room";
import {
  DASHBOARD_PATH,
  PRIVATE_ROOM_PATH,
} from "../../../../../../constants/paths";

const PrivateRoomDetail = ({ auth, user, remove }) => {
  const { privateRoomId } = useParams();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [error, setError] = useState(null);
  const [copyMessage, setCopyMessage] = useState(null);

  useFirestoreConnect([
    {
      collection: "private_rooms",
      doc: (user && user.current_user_id) || auth.uid,
      subcollections: [
        {
          collection: "private_rooms",
          doc: privateRoomId,
        },
      ],
      storeAs: "private_room",
    },
  ]);
  const privateRoomData = useSelector(
    (state) => state.firestore.ordered.private_room,
  );

  useEffect(() => {
    Mixpanel.page("private_room", { private_room: privateRoomId });
  }, [privateRoomId]);

  useEffect(() => {
    return () => {};
  }, []);

  const handleConfirm = async () => {
    setError(null);

    try {
      await remove(privateRoomId);
      navigate(-1);
    } catch (e) {
      setError(e);
    }
  };

  const handleCopy = () => {
    setCopyMessage("Copied!");
    setTimeout(() => setCopyMessage(null), 1000);
  };

  const privateRoom = privateRoomData && new PrivateRoom(privateRoomData[0]);

  return (
    <AuthLayout
      title={privateRoom && privateRoom.name}
      description={
        privateRoom && privateRoom.about && privateRoom.about.slice(0, 100)
      }
    >
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Breadcrumb>
              <Breadcrumb.Section
                as={Link}
                to={DASHBOARD_PATH}
                content={"Home"}
              />
              <Breadcrumb.Divider />
              <Breadcrumb.Section
                as={Link}
                to={PRIVATE_ROOM_PATH}
                content={"Private Rooms"}
              />
              <Breadcrumb.Divider />
              <Breadcrumb.Section
                active
                content={privateRoom && privateRoom.name}
              />
            </Breadcrumb>
          </Grid.Column>
          <Grid.Column>
            {privateRoom && privateRoom.user === auth.uid && (
              <>
                <Button
                  basic
                  floated={"right"}
                  onClick={() => setConfirmOpen(true)}
                  content={"Delete Private Room"}
                  icon={"close"}
                  labelPosition={"left"}
                />
                <Confirm
                  open={confirmOpen}
                  onCancel={() => setConfirmOpen(false)}
                  onConfirm={handleConfirm}
                  content={"Are you sure you want to delete this Private Room?"}
                />
              </>
            )}

            {privateRoom && privateRoom.user === auth.uid && (
              <EditPrivateRoomSidePane privateRoom={privateRoom}>
                <Button
                  basic
                  floated="right"
                  icon={"edit"}
                  content={"Edit Private Room"}
                  labelPosition={"left"}
                />
              </EditPrivateRoomSidePane>
            )}

            {privateRoom && privateRoom.user === auth.uid && (
              <CopyToClipboard
                text={`https://www.artcollection.io/private_room/${privateRoomId}`}
              >
                <div>
                  <Button
                    basic={copyMessage === null}
                    positive={copyMessage !== null}
                    floated="right"
                    content={
                      copyMessage === null
                        ? "Share Private Room"
                        : "Link Copied to Clipboard"
                    }
                    icon={copyMessage === null ? "share" : "check"}
                    labelPosition="left"
                    onClick={handleCopy}
                  />
                </div>
              </CopyToClipboard>
            )}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Transition.Group
              animation={"fade down"}
              duration={600}
              visible={privateRoom}
            >
              {privateRoom && <Header size="huge" content={privateRoom.name} />}
            </Transition.Group>
            <Transition.Group
              animation={"fade up"}
              duration={600}
              visible={privateRoom}
            >
              {privateRoom && privateRoom.about && <p>{privateRoom.about}</p>}
            </Transition.Group>
          </Grid.Column>
        </Grid.Row>

        {isLoaded(privateRoomData) && !isEmpty(privateRoomData) && (
          <Grid.Row>
            <Grid.Column width={16}>
              <PrivateRoomPieceRecords privateRoom={privateRoom} />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ firebase, privateRoom }) => ({
  user: firebase.profile || null,
  auth: firebase.auth || null,
  privateRoom: privateRoom.private_room,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(getPrivateRoom(id)),
  remove: (id) => dispatch(deletePrivateRoom(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoomDetail);
