import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import ManageBilling from '../ManageBilling';

const PremiumAccount = () => {
  const user = useSelector(state => state.firebase.profile || null);

  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Segment>
          <Segment.Inline>
            You are on a Premium Account
          </Segment.Inline>
        </Segment>
        <ManageBilling user={user} />
      </Grid.Column>
    </Grid.Row>
  );
};

export default PremiumAccount;
