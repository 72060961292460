import React from "react";
import { Form } from "semantic-ui-react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, 0);
const toMonth = new Date(currentYear + 10, 11);

const YearMonthForm = ({ date, localeUtils, onChange }) => {
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
};

const FormDatePicker = ({ label, name, handleDayChange, value, width }) => (
  <Form.Input
    width={width}
    label={label}
    control={DayPickerInput}
    name={name}
    value={value && `${formatDate(value, "LL")}`}
    formatDate={formatDate}
    parseDate={parseDate}
    format={"LL"}
    onDayChange={handleDayChange}
    placeholder={`${formatDate(new Date(), "LL")}`}
    captionElement={({ date, localeUtils }) => (
      <YearMonthForm
        date={date}
        localeUtils={localeUtils}
        onChange={this.handleYearMonthChange}
      />
    )}
  />
);

export default FormDatePicker;
