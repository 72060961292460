import React from 'react';
import Slider from 'react-slick';
import moment from 'moment';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Card, Header, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { AUCTION_PATH } from '../../../../constants/paths';

const UpcomingAuctionsList = ({ auctions }) => {
  if (!auctions || auctions.length === 0) return null;

  return (
    <Segment basic>
      <Slider
        infinite={false}
        dots
        speed={500}
        slidesToShow={3}
        slidesToScroll={1} // Corrected typo from sidesToScroll to slidesToScroll
      >
        {auctions.map(({ _id, end_date, name, image_url, auction_house }) => (
          <Segment basic key={_id}>
            <Link to={`${AUCTION_PATH}/${_id}`}>
              <Card
                fluid
                style={{
                  backgroundImage: `url(${image_url})`,
                  backgroundSize: 'cover',
                  height: '20em',
                }}
              />
            </Link>
            <Header
              content={name}
              subheader={`${end_date ? moment(end_date).format('MMM D, YYYY') : ''} @ ${auction_house.name}`}
              textAlign='center'
            />
          </Segment>
        ))}
      </Slider>
    </Segment>
  );
};

export default UpcomingAuctionsList;
