import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import UploadAttachmentModal from "../../../../../components/Modals/UploadAttachment";
import Attachment from "../../../../../components/Attachment";

const Attachments = ({ attachments: initialAttachments, type, onAttachmentsUpdated }) => {
  const [attachments, setAttachments] = useState(initialAttachments || []);

  const onAttachmentCreated = useCallback((attachment) => {
    const newAttachments = [attachment, ...attachments];
    setAttachments(newAttachments);
    onAttachmentsUpdated(newAttachments);
  }, [attachments, onAttachmentsUpdated]);

  const onAttachmentDeleted = useCallback((attachment) => {
    const filteredAttachments = attachments.filter((a) => a._id !== attachment._id);
    setAttachments(filteredAttachments);
    onAttachmentsUpdated(filteredAttachments);
  }, [attachments, onAttachmentsUpdated]);

  return (
    <Form.Group>
      <Form.Field width={4} />
      <Form.Field width={9}>
        {attachments.map((attachment) => (
          <Attachment
            key={attachment._id}
            attachment={attachment}
            onAttachmentDeleted={onAttachmentDeleted}
          />
        ))}
      </Form.Field>
      <Form.Field width={3}>
        <UploadAttachmentModal
          type={type}
          handleAttachmentUploaded={onAttachmentCreated}
        />
      </Form.Field>
    </Form.Group>
  );
};

Attachments.propTypes = {
  attachments: PropTypes.array,
  type: PropTypes.string.isRequired,
  onAttachmentsUpdated: PropTypes.func.isRequired,
};

export default Attachments;
