const url = "/api/v5/auction_records";

export const RESET_AUCTION_RECORD_STATE = "RESET_AUCTION_RECORD_STATE";

export const CREATE_AUCTION_RECORD = "CREATE_AUCTION_RECORD";
export const CREATE_AUCTION_RECORD_SUCCESS = "CREATE_AUCTION_RECORD_SUCCESS";
export const CREATE_AUCTION_RECORD_FAILURE = "CREATE_AUCTION_RECORD_FAILURE";

export const UPDATE_AUCTION_RECORD = "UPDATE_AUCTION_RECORD";
export const UPDATE_AUCTION_RECORD_SUCCESS = "UPDATE_AUCTION_RECORD_SUCCESS";
export const UPDATE_AUCTION_RECORD_FAILURE = "UPDATE_AUCTION_RECORD_FAILURE";

export const DELETE_AUCTION_RECORD = "DELETE_AUCTION_RECORD";
export const DELETE_AUCTION_RECORD_SUCCESS = "DELETE_AUCTION_RECORD_SUCCESS";
export const DELETE_AUCTION_RECORD_FAILURE = "DELETE_AUCTION_RECORD_FAILURE";

export const GET_AUCTION_RECORD_LIST = "GET_AUCTION_RECORD_LIST";
export const GET_AUCTION_RECORD_LIST_SUCCESS =
  "GET_AUCTION_RECORD_LIST_SUCCESS";
export const GET_AUCTION_RECORD_LIST_FAILURE =
  "GET_AUCTION_RECORD_LIST_FAILURE";

export const GET_AUCTION_RECORD = "GET_AUCTION_RECORD";
export const GET_AUCTION_RECORD_SUCCESS = "GET_AUCTION_RECORD_SUCCESS";
export const GET_AUCTION_RECORD_FAILURE = "GET_AUCTION_RECORD_FAILURE";

export const TOGGLE_AUCTION_RECORD_LIST_DISPLAY =
  "TOGGLE_AUCTION_RECORD_LIST_DISPLAY";

export const getAuctionResultList = (
  page = 1,
  limit = 25,
  artist_filter = "all",
  lot_filter = "upcoming",
) => {
  return {
    types: [
      GET_AUCTION_RECORD_LIST,
      GET_AUCTION_RECORD_LIST_SUCCESS,
      GET_AUCTION_RECORD_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
          artist_filter,
          lot_filter,
        },
      },
    },
  };
};

export const getAuctionResult = (id) => {
  return {
    types: [
      GET_AUCTION_RECORD,
      GET_AUCTION_RECORD_SUCCESS,
      GET_AUCTION_RECORD_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
      },
    },
  };
};

export const createAuctionResult = (data) => {
  return {
    types: [
      CREATE_AUCTION_RECORD,
      CREATE_AUCTION_RECORD_SUCCESS,
      CREATE_AUCTION_RECORD_FAILURE,
    ],
    payload: {
      request: {
        url,
        method: "POST",
        data,
      },
    },
  };
};

export const updateAuctionResult = (id, data) => {
  return {
    types: [
      UPDATE_AUCTION_RECORD,
      UPDATE_AUCTION_RECORD_SUCCESS,
      UPDATE_AUCTION_RECORD_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "PUT",
        data,
      },
    },
  };
};

export const deleteAuctionResult = (id, data) => {
  return {
    types: [
      DELETE_AUCTION_RECORD,
      DELETE_AUCTION_RECORD_SUCCESS,
      DELETE_AUCTION_RECORD_FAILURE,
    ],
    payload: {
      request: {
        url: `${url}/${id}`,
        method: "DELETE",
        data,
      },
    },
  };
};

export const resetAuctionResultState = () => {
  return {
    type: RESET_AUCTION_RECORD_STATE,
  };
};

export const toggleAuctionRecordListDisplay = (display) => {
  console.log(display);
  return {
    type: TOGGLE_AUCTION_RECORD_LIST_DISPLAY,
    display,
  };
};

export const searchAuctionResults = (
  page = 1,
  limit = 25,
  query = {},
) => {
  return {
    types: [
      GET_AUCTION_RECORD_LIST,
      GET_AUCTION_RECORD_LIST_SUCCESS,
      GET_AUCTION_RECORD_LIST_FAILURE,
    ],
    payload: {
      request: {
        url,
        params: {
          page,
          limit,
          ...query,
        },
      },
    },
  };
};
