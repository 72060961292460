import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Dimmer, Header, Segment } from "semantic-ui-react";
import { LOGIN_PATH, PRODUCT_PATH } from "../constants/paths";

const UpgradeOverlay = ({ children, user }) => {
  if (user && user.plan === "premium") {
    return children;
  } else if (user && user.plan === "free") {
    return (
      <Dimmer.Dimmable as={Segment} dimmed={true} blurring={false}>
        {children}
        <Dimmer>
          <Segment>
            <Header
              content={"This is a premium feature"}
              subheader={"Please upgrade"}
            />
            <Button primary content={"Upgrade"} as={Link} to={PRODUCT_PATH} />
          </Segment>
        </Dimmer>
      </Dimmer.Dimmable>
    );
  } else if (user) {
    return (
      <Dimmer.Dimmable as={Segment} dimmed={true} blurring={false}>
        {children}
        <Dimmer>
          <Segment>
            <Header
              content={"You are not subscribed to a plan"}
              subheader={"Please contact support"}
            />
            <Button primary content={"Contact"} as={Link} to={"/contact"} />
          </Segment>
        </Dimmer>
      </Dimmer.Dimmable>
    );
  } else {
    return (
      <Dimmer.Dimmable as={Segment} dimmed={true} blurring={false}>
        {children}
        <Dimmer>
          <Segment>
            <Header
              content={"You are not logged in"}
              subheader={"Please login"}
            />
            <Button primary content={"Login"} as={Link} to={LOGIN_PATH} />
          </Segment>
        </Dimmer>
      </Dimmer.Dimmable>
    );
  }
};

const mapStateToProps = ({ firebase }) => ({
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeOverlay);
