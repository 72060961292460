import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Header, Segment } from "semantic-ui-react";
import { getSellThroughRates } from "../../../../../../redux/actions/artist";
import { ResponsiveLine } from "@nivo/line";
import ErrorMessage from "../../../../../../components/ErrorMessage";

const ArtistSellThroughRates = ({ artistId, fetchData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!artistId) return;

    const fetchSellThroughRates = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetchData(artistId);
        setData(response.payload.data || []);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSellThroughRates();
  }, [artistId, fetchData]);

  const totalLots = useMemo(
    () => data.reduce((acc, lot) => acc + lot.number_of_lots_total, 0),
    [data]
  );

  const lotsSold = useMemo(
    () => data.reduce((acc, lot) => acc + lot.number_of_lots_sold, 0),
    [data]
  );

  const averageRate = useMemo(
    () => (totalLots ? ((lotsSold / totalLots) * 100).toFixed(2) : 0),
    [lotsSold, totalLots]
  );

  const chartData = useMemo(
    () => [
      {
        id: "sell-through rate",
        data: data.map((d) => ({
          x: d._id,
          y: Math.round((d.number_of_lots_sold / d.number_of_lots_total) * 100),
        })),
      },
    ],
    [data]
  );

  return (
    <Segment basic compact loading={isLoading}>
      <Header
        textAlign="center"
        content="Sell-Through Rates"
        subheader={`Average Total Rate: ${averageRate} %`}
      />
      <p style={{ textAlign: "center" }}>
        An artist's sell-through rate (lots sold/total lots offered) is an
        important measure of market demand, which can help inform buying or
        selling decisions. A high sell-through rate indicates strong demand for
        the lots offered at auction, pointing to a liquid market for the artist. 
        Generally, low sell-through rates suggest a period of lesser demand.
      </p>

      <div style={{ height: 400 }}>
        {error && <ErrorMessage error={error} />}
        {!isLoading && chartData[0].data.length === 0 && <p>No data available</p>}
        {chartData[0].data.length > 0 && (
          <ResponsiveLine
            data={chartData}
            margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
            yScale={{ type: "linear", min: 0, max: 100, stacked: true }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Year",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Sell-Through Rate (%)",
              legendOffset: -40,
              legendPosition: "middle",
            }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh
          />
        )}
      </div>
    </Segment>
  );
};

const mapStateToProps = ({ firebase }) => ({
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: (id) => dispatch(getSellThroughRates(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistSellThroughRates);