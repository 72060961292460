import React, { useState, useEffect } from "react";
import { Grid, Header, Segment, Table } from "semantic-ui-react";
import { connect, useSelector } from "react-redux";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import CreateInvitationModal from "../CreateInvitationModal";
import InvitationCell from "./InvitationCell";
import Invitation from "../../../../../../../models/invitation";

const Invitations = ({ accountID }) => {
  useFirestoreConnect([
    {
      collection: "accounts",
      doc: accountID,
      subcollections: [{ collection: "invitations" }],
      storeAs: "invitations",
    },
  ]);

  const invitations = useSelector(
    (state) => state.firestore.ordered.invitations,
  );
  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Segment loading={!isLoaded(invitations)}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Header
                  size={"medium"}
                  content={"Users"}
                  subheader={accountID}
                  textAlign={"left"}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <CreateInvitationModal floated={"right"} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Email</Table.HeaderCell>
                      <Table.HeaderCell>Role</Table.HeaderCell>
                      <Table.HeaderCell>Invitation Status</Table.HeaderCell>
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {invitations &&
                      invitations
                        .map((invitation) => new Invitation(invitation))
                        .map((invitation) => (
                          <InvitationCell
                            key={invitation._id}
                            invitation={invitation}
                          />
                        ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

const mapStateToProps = ({ firebase }) => ({
  auth: firebase.auth || null,
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Invitations);
