import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Header, Segment } from "semantic-ui-react";
import { getNumberOfArtworksSold } from "../../../../../../redux/actions/artist";
import { ResponsiveLine } from "@nivo/line";
import ErrorMessage from "../../../../../../components/ErrorMessage";

const ArtistAuctionRecordsRate = ({ artistId, fetchData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!artistId) return;

    const fetchArtworksData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetchData(artistId);
        setData(response.payload.data || []);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArtworksData();
  }, [artistId, fetchData]);

  const totalArtworksSold = useMemo(
    () => data.reduce((acc, record) => acc + record.count, 0),
    [data]
  );

  const chartData = useMemo(
    () => [
      {
        id: "auction records",
        data: data.map((record) => ({
          x: record._id,
          y: record.count,
        })),
      },
    ],
    [data]
  );

  return (
    <Segment basic compact loading={isLoading}>
      <Header
        textAlign="center"
        content="Number of Artworks Sold"
        subheader={`Total Sold: ${totalArtworksSold}`}
      />
      <p style={{ textAlign: "center" }}>
        This chart provides an overview of the number of artworks sold by the
        artist in a given year. It serves as an insightful tool to gauge the
        popularity of the artist and track demand trends for their works over time.
      </p>

      <div style={{ height: 400 }}>
        {error && <ErrorMessage error={error} />}
        {!isLoading && chartData[0].data.length === 0 && <p>No data available</p>}
        {chartData[0].data.length > 0 && (
          <ResponsiveLine
            data={chartData}
            margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
            yScale={{ type: "linear", min: 0, max: "auto", stacked: true }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Year",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Artworks Sold",
              legendOffset: -40,
              legendPosition: "middle",
            }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh
          />
        )}
      </div>
    </Segment>
  );
};

const mapStateToProps = ({ firebase }) => ({
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: (id) => dispatch(getNumberOfArtworksSold(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistAuctionRecordsRate);