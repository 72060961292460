import React from "react";
import { Image, Table } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import placeholder from "../../../../images/placeholder.png";
import { LOCATION_PATH } from "../../../../constants/paths";

const LocationTile = ({ storageLocation }) => {
  const navigate = useNavigate();

  const handleRowClick = (e, url) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  const { _id, name, small_image_url, number_of_pieces } = storageLocation;

  return (
    <Table.Row onClick={(e) => handleRowClick(e, `${LOCATION_PATH}/${_id}`)}>
      <Table.Cell>
        <Image src={small_image_url || placeholder} avatar />
        {name}
      </Table.Cell>
      <Table.Cell content={number_of_pieces} />
    </Table.Row>
  );
};

export default LocationTile;