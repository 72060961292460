import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, Header, Image, List } from "semantic-ui-react";
import placeholder from "../../../../../../../../images/placeholder.png";
import { Link } from "react-router-dom";

const Cell = ({ header, content, image, link }) => {
  return (
    <Grid.Column>
      <List>
        <List.Item>
          <List.Content>
            <Header size="small" content={header} />
            {!link && (
              <List.Description>
                {image && <Image src={image || placeholder} avatar />}
                {content || "N/A"}
              </List.Description>
            )}
            {link && (
              <List.Description as={Link} to={{ pathname: link }}>
                {image && <Image src={image || placeholder} avatar />}
                {content || "N/A"}
              </List.Description>
            )}
          </List.Content>
        </List.Item>
      </List>
    </Grid.Column>
  );
};

const Valuation = ({ piece }) => {
  const { valuation_price } = piece;

  if (!valuation_price) {
    return null;
  }

  return (
    <Grid.Row columns={1}>
      <Grid.Column>
        <Header content={"Valuation"} dividing />
        <Cell
          header={"Valuation Price"}
          content={piece.valuationPriceString()}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

Valuation.propTypes = {
  piece: PropTypes.object,
};

export default Valuation;
